.section {
  width: 100%;
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.backIcon {
  width: 21px;
  height: 18px;
  cursor: pointer;
  margin: 0 20px 4px 0;
}

.subjectSelect {
  width: max(40%, 250px);
}
.calendarSection {
  margin-top: 20px;
  width: 80%;
}
.saveButton {
  margin-top: 20px;
}

/* step1 */

.toolbar {
  display: flex;
  align-items: center;
  gap: 20px;
}

.attendanceCalendar {
  width: 95%;
}

/* step2 */
.teacherList {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(337px, 1fr));
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}

.teacherInfo {
  position: relative;
}
.teacherInfo .icon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background: var(--purpletronic);
  border-radius: 50%;
  animation: move 2s infinite alternate ease-in-out;
}

@keyframes move {
  0% {
    transform: scale(1);
    background-color: var(--purpletronic-30);
  }
  100% {
    transform: scale(1.1);
    background-color: var(--purpletronic-15);
  }
}

.teacherModalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 25px 35px;
  position: relative;
}
.teacherModalInfo .closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.teacherModalInfo .paragraph {
  color: var(--purpletronic);
}
.teacherModalInfo .dayLabel {
  font-weight: 600;
  line-height: var(--font-size-30);
}
.teacherModalInfo .advice {
  width: 100%;
  color: var(--sandground);
  text-align: center;
}
