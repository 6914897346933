.videoContainer {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  height: 120px;
  background: url(assets/images/robot2.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 80px;
  pointer-events: none;
}
.primary {
  border: 5px solid var(--purpletronic);
}
.secondary {
  border: 5px solid var(--seadapted);
  background: url(assets/images/robot4.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.small {
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  width: 100px;
  height: 100px;
}
.medium {
  min-width: 120px;
  min-height: 120px;
  max-width: 120px;
  max-height: 120px;
  width: 120px;
  height: 120px;
}
.large {
  min-width: 135px;
  min-height: 135px;
  max-width: 135px;
  max-height: 135px;
  width: 135px;
  height: 135px;
}

.video {
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
}

.unfocused {
  opacity: 0.5;
}
