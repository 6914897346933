.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: 8px 24px;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: var(--purpletronic-40);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  backdrop-filter: blur(1px);
}
.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: inherit;
  overflow: auto;
  isolation: isolate;
  z-index: 300;
  filter: blur(0);
  -webkit-filter: blur(0);
  padding: 20px;
  animation: fadeIn 0.3s ease-in-out;
}
.closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  background: var(--dark-grey);
  border-radius: 50%;
}

.arrow {
  position: absolute;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--deepblue);
  transition: transform 0.2s ease;
  background-color: var(--dark-grey);
}

.arrow:hover {
  background-color: var(--purpletronic);
  transform: scale(1.05);
}
.leftArrow {
  order: 1;
  left: 20px;
}

.rightArrow {
  order: 3;
  right: 20px;
}

.selectedPhotoContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  min-width: 300px;
  min-height: 200px;
  width: 90%;
  height: inherit;
  overflow: hidden;
  order: 2;
}

.selectedPhoto {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
  margin: auto;
  transform-origin: center center;
  transition: transform 0.4s ease-in-out;
}

.rotateIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
  bottom: 0.75rem;
}
.rotateLeftIcon,
.rotateRightIcon {
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.rotateRightIcon {
  transform: scaleX(-1);
}

.hints {
  position: absolute;
  left: 0;
  bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.hint {
  color: var(--dark-grey-40);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
