.section {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.spinner {
  font-weight: 700;
  font-size: var(--font-size-50);
  color: var(--seadapted);
}
.toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
  width: 100%;
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 30px 0;
}
