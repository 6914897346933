.tabs {
  display: flex;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: none;
  color: var(--dark-grey-30);
  transition: color 0.3s;
  border-bottom: 2px solid var(--purpletronic-20);
}

.tab:last-child {
  margin-right: 0;
}

.tab:hover {
  border-radius: 0 1rem;
  color: var(--dark-grey-40);
  background-color: var(--seadapted-10);
}

.tab.active {
  color: var(--purpletronic);
  border-bottom: 2px solid var(--seadapted);
}

.tab.disabled {
  cursor: not-allowed;
  background-color: var(--dark-grey-20);
}

.tabContent {
  width: 100%;
  height: 100%;
}
