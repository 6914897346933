.tag {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  border: 0;
  min-width: 84px;
  min-height: 28px;
  background: var(--purpletronic-30);
  border-radius: 42px;
  text-align: center;
  padding: 0 15px;
}

.span {
  font-weight: 700;
  font-size: var(--font-size-12);
  color: var(--purpletronic);
}
