.uploadPhotoSection {
  max-width: 480px;
  min-width: 450px;
  max-height: 450px;
  margin: 15px;
  padding: 5px;
}

.uploadDescriptionContainer,
.qr {
  margin: 15px 0;
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}
