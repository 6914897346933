.stepper {
  width: 100%;
  height: 100%;
}
.stepperContainer {
  display: flex;
  justify-content: space-between;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--purpletronic);
  cursor: pointer;
  transition: color 0.2s;
  position: relative;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  font-size: var(--font-size-12);
  line-height: var(--font-size-14);
}

.step:hover {
  color: var(--seadapted);
}
.checkIconWrapper {
  width: 22px;
  height: 22px;
  padding: 4px;
  border-radius: 50%;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--purpletronic);
  color: var(--bright);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: var(--font-size-16);
  margin-bottom: 8px;
  position: relative;
  z-index: 2;
}

.active .circle {
  background-color: var(--seadapted);
}

.step:not(.active) {
  color: var(--dark-grey-30);
}
.step:not(.active):hover {
  color: var(--seadapted);
}

.step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 15px;
  right: 0;
  width: 15px;
  height: 2px;
  background-color: var(--purpletronic);
  z-index: 1;
}
.step:not(:first-child)::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 0;
  width: 15px;
  height: 2px;
  background-color: var(--purpletronic);
  z-index: 1;
}
.stepContent {
  padding: 5px;
}
