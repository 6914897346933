.container {
  display: flex;
  gap: 60px;
  width: 100%;
  height: 100%;
  user-select: none;
}
.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.calendarWrapper {
  width: 80%;
  height: 100%;
}

/* actions */
.actions {
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 40px;
}

.chevron {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.chevronDisabled {
  cursor: not-allowed;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-16);
  text-align: center;
  letter-spacing: var(--letter-spacing-5);
  color: var(--purpletronic);
  padding: 0 5px;
  min-width: 170px;
  text-transform: capitalize;
}

/* calendar content*/

.tableWrapper {
  width: 100%;
  height: 85%;
  box-shadow: 0px 3px 68px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 15px;
}
.table {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.thead {
  vertical-align: middle;
}
.tr {
  height: 2rem;
}

.th {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-24);
  color: var(--seadapted);
}

.dayCell {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  text-align: center;
  color: var(--purpletronic);
  cursor: pointer;
}
.dayCell:hover {
  opacity: 0.8;
  transition: 0.15s;
  font-size: 1.1rem;
}
.dayCell p {
  margin: 0 auto;
}
.dayCellDisabled {
  color: var(--disabled-color) !important;
  cursor: not-allowed;
}

.today {
  font-weight: 500;
  color: var(--seadapted);
}

.selectedDate {
  display: grid;
  place-items: center;
  height: 2.2rem;
  width: 2.2rem;
  font-weight: 700;
  color: var(--bright);
  background: var(--purpletronic);
  border-radius: 50px;
}

.dayCellOutOfRange {
  color: var(--disabled-color-24);
}

/* hours */
.lateralHours {
  min-width: 160px;
  width: 20%;
  height: 100%;
}
.lateralHoursTitle {
  width: 100%;
  padding: 10px 0 0 10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 40px;
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-16);
  letter-spacing: var(--letter-spacing-2);
  text-align: center;
  color: var(--purpletronic);
}

.lateralHoursContent {
  box-shadow: 0px 3px 68px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  width: 100%;
  height: 85%;
  padding: 10px 15px;
  display: grid;
  gap: 5px;
}

.hourSection {
  width: 95%;
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
}
.hourSectiontitle {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-16);
  letter-spacing: var(--letter-spacing-2);
  color: var(--seadapted);
  margin-bottom: 8px;
}

.hourContainer {
  display: grid;
  grid-auto-rows: 2.0625rem;
  align-items: center;
  height: 40px;
  min-height: calc(100% - 40px);
  overflow-y: auto;
}
.hour {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-14);
  text-align: center;
  color: var(--purpletronic);
  cursor: pointer;
}
.hour:hover {
  opacity: 0.8;
  transition: 0.15s;
}
.selectedHour {
  padding: 4px;
  display: grid;
  place-items: center;
  font-weight: 700;
  color: var(--bright);
  background: var(--purpletronic);
  border-radius: 50px;
  height: fit-content;
}

.emptyHours {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  font-weight: 600;
  color: var(--purpletronic);
}

.disabledHour {
  opacity: 0.3;
  cursor: not-allowed;
}
.disabledHour:hover {
  opacity: 0.3;
}
