.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: 8px 24px;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: var(--purpletronic-40);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.modalWrapper {
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0px 0px 35px 0px rgba(144, 144, 174, 0.2);
  background: var(--bright);
  border-radius: 26px;
  overflow: auto;
  isolation: isolate;
  z-index: 300;
  filter: blur(0);
  -webkit-filter: blur(0);
}
.closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modalContent {
  position: relative;
  border-radius: 26px 26px 0 0;
  overflow: auto;
  --maxModalContentHeight: calc(100vh - 150px);
  max-height: var(--maxModalContentHeight);
  padding: 20px;
}
.modalActions {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.5px solid var(--disabled-color-24);
  padding: 8px;
}
