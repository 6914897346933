.paymentMethodsSection {
  height: 100%;
  width: max(100%, 420px);
  overflow: auto;
  padding-bottom: 50px;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.backIcon {
  width: 21px;
  height: 22px;
  cursor: pointer;
  margin: 0 20px 0 0;
}
.largeRectangle {
  width: 320px;
  height: 90px;
  border-radius: 15px;
  border: 2px solid var(--purpletronic-40);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
}
.smallRectangle {
  width: 75px;
  height: 45px;
  border: 2px dashed #ccc;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addPaymentText {
  font-size: 14px;
}
.circle {
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: bold;
  color: #ccc;
}

.noPaymentMethodMessage {
  margin-top: 35px;
}
.creditCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 45px;
}

.confirmDeleteModalContainer {
  width: 450px;
  height: 100px;
}

.deleteModalDescription {
  margin: 12px 0;
}
