.details {
  border-bottom: 1px solid var(--dadada);
}

.summary {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.summary::marker {
  display: none;
}
.summary::-webkit-details-marker {
  display: none;
}

.summaryRow {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  width: 100%;
  min-height: 60px;
  padding: 10px;
}

.summaryTitle {
  color: var(--text-color-item);
}

.summaryIncomeData {
  text-align: center;
  color: var(--dark-grey-40);
}

.placeholder {
  color: var(--dark-grey-40);
  font-style: italic;
}
