.radio {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: fit-content;
}
.radio input {
  -webkit-appearance: none;
  appearance: none;
  background: var(--bright);
  border: 1px solid var(--purpletronic);
  height: 1em;
  width: 1em;
  min-height: 1em;
  min-width: 1em;
  border-radius: 100%;
  cursor: inherit;
}

.radio input[type='radio']:checked {
  border: 1px solid var(--bright);
  border-radius: 100%;
  border-width: 0.15rem;
  background: var(--purpletronic);
  box-shadow: 0 0 0 1px var(--purpletronic);
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.label {
  width: calc(100% - 1em);
  font-weight: 400;
  font-size: var(--font-size-12);
  line-height: var(--font-size-14);
  color: var(--dark-grey-40);
  cursor: inherit;
}
