.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  min-height: 100vh;
  background: var(--purpletronic);
  mix-blend-mode: normal;
  text-align: center;
  position: relative;
}
.classfyLogo {
  left: 0;
  top: 0;
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}
.video {
  background: var(--deepblue);
  border-radius: 50%;
}
.h2 {
  text-align: center;
  color: var(--bright);
}
.images {
  display: inline-flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 1.65rem;
  height: 1.65rem;
}
.images .mainSvg {
  position: absolute;
}
.images .backgroundSvg {
  position: absolute;
  right: 0.1rem;
}
.seadapted {
  color: var(--seadapted);
}
.sandground {
  color: var(--sandground);
}
.description {
  color: var(--bright);
  font-size: var(--font-size-20);
  line-height: var(--line-heights-24);
  letter-spacing: var(--letter-spacing-0);
  font-weight: 500;
}
.setupSection {
  display: grid;
  gap: 20px;
  text-align: left;
  grid-template-columns: 220px 220px 220px;
  margin-bottom: 15px;
}
.setupSectionTwoColumns {
  grid-template-columns: 220px 220px;
}
.selectLabel {
  text-align: center;
  color: var(--dark-grey);
}
.micLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
}
.micLevelMeter {
  width: 35%;
  height: 0.5rem;
  background-color: var(--disabled-color);
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0, 0.3);
  overflow: hidden;
}
.micLevelBar {
  height: 100%;
  background: var(--deepblue);
  width: 0%;
  transition: width 0.1s ease-out;
  border-radius: 10px 0 0 10px;
}

.hint {
  color: var(--bright);
}
.hint span {
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--seadapted);
  cursor: pointer;
}

@media (width < 800px) {
  .section {
    padding: 0 1rem 1.5rem 1rem;
    gap: 1.25rem;
  }
  .classfyLogo {
    padding: 1rem;
    position: sticky;
    display: flex;
    height: 70px;
  }
  .setupSection {
    gap: 1rem;
    grid-template-columns: 220px;
  }
}
