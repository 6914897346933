.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  height: 100vh;
  min-width: 500px;
  min-height: 100vh;
  background: var(--purpletronic);
  mix-blend-mode: normal;
  position: relative;
  overflow-y: auto;
}

.classfyLogo {
  left: 0;
  top: 0;
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.images {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 470px;
  height: 250px;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 13px;
  right: 6px;
}

.innerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
}
.email {
  width: 393.33px;
  height: 53.79px;
  background: white;
  border-radius: 34px;
  border: 1px solid var(--purpletronic);
  box-sizing: border-box;
  padding: 12px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--shade);
}

.email:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic-50);
}
.email::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.innerContainer button {
  width: 148px !important;
  margin-top: 40px;
}

.h2 {
  width: 600px;
  text-align: center;
  color: var(--bright) !important;
}
.h2 span {
  color: var(--seadapted);
}

@media (width < 500px) {
  .main {
    min-width: 250px;
    padding: 0 1rem 1.5rem 1rem;
    gap: 2rem;
  }
  .classfyLogo {
    padding: 1rem;
    position: relative;
    display: flex;
    height: 70px;
  }
  .images {
    width: 100%;
    height: 150px;
  }
  .innerContainer {
    width: 100%;
    align-items: center;
  }
  .email {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .h2 {
    width: 100%;
  }
}
