.section {
  background: var(--bright);
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.container {
  width: 80%;
}

.item {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  line-height: var(--font-size-30);
  color: var(--grey);
  padding: 5px 20px;
  width: fit-content;
  border-radius: 42px;
  cursor: pointer;
  margin-bottom: 2px;
}

.selected {
  background: var(--purpletronic);
  color: var(--bright);
}

.selectedText {
  font-weight: 700;
}

.item:hover,
.selected:hover {
  transition: 0.3s;
  font-size: 1.025rem;
}

.notifications {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 7px;
  font-size: var(--font-size-17);
  background: var(--bright);
  color: var(--purpletronic);
  font-weight: 700;
}

.notSelectedBadge {
  background: var(--purpletronic);
  color: var(--bright);
}
