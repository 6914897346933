.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: 8px 24px;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: var(--purpletronic-40);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  backdrop-filter: blur(1px);
}

.content {
  width: fit-content;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  overflow: auto;
  isolation: isolate;
  z-index: 300;
  filter: blur(0);
  -webkit-filter: blur(0);
  animation: fadeIn 0.3s ease-in-out;
  background: var(--bright);
  border-radius: 25px;
}
.closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}
.studentName {
  font-weight: bold;
}
.h4 {
  text-align: center;
}
.ratingSection {
  width: 515px;
  min-height: 50px;
  display: grid;
  place-items: center;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  grid-template-columns: repeat(6, minmax(12px, 1fr));
  background: var(--purpletronic-30);
  box-shadow: 5px 5px 17.5px 4px rgba(144, 144, 174, 0.3);
  border-radius: 25px;
}
.paragraph {
  color: var(--purpletronic);
}

.emoji {
  scale: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.emoji:hover {
  scale: 2;
  transition: scale 0.3s ease;
}
.selectedEmoji {
  scale: 2;
  border-radius: 50px;
  height: 0.5rem;
  width: 0.5rem;
  box-shadow: 0 0 6px 6px var(--seadapted);
}
.negative {
  box-shadow: 0 0 6px 6px var(--sandground);
}

.commentsArea {
  width: 100%;
}

.commentsLimit {
  text-align: right;
  font-size: var(--font-size-11);
  line-height: var(--line-heights-10);
  color: var(--dark-grey-40);
  margin-top: 4px;
}
