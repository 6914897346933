.wrapper {
  width: inherit;
}

.picker {
  min-width: 100px;
  width: inherit;
  height: 45px;
  background: white;
  border-radius: 34px;
  border: 1px solid var(--purpletronic-40);
  box-sizing: border-box;
  padding: 5px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--shade);
}
.picker:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic);
}
.picker::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.error {
  border: 1px solid var(--sandground);
}
