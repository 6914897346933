.creditCard {
  width: 320px;
  height: 170px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  padding: 0 15px;
  border: 2px solid var(--dadada);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.creditCard:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.creditCardFavourite {
  border: 2px solid var(--seadapted-50);
}
.cardTop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 0;
}
.cardBottom {
  display: flex;
  flex-direction: column;
}
.cardNumber {
  font-size: 20px;
  padding: 10px 0;
  margin: 0 auto;
}
.cardInfo {
  display: flex;
  justify-content: space-between;
}
.cardHolder,
.expiryDate {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}
.creditCardBrandIcon {
  align-self: flex-end;
  display: inline-block;
  height: 40px;
  margin: 2px;
  text-indent: 20px;
  vertical-align: middle;
  width: 60px;
}
.cardIconAction {
  width: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.starIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.binIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.brand--american_express {
  background: url('assets/images/creditCards/american_express.png') no-repeat;
  background-size: 100% 100%;
}
.brand--cirrus {
  background: url('assets/images/creditCards/cirrus.png') no-repeat;
  background-size: 100% 100%;
}
.brand--delta {
  background: url('assets/images/creditCards/delta.png') no-repeat;
  background-size: 100% 100%;
}
.brand--direct_debit {
  background: url('assets/images/creditCards/direct_debit.png') no-repeat;
  background-size: 100% 100%;
}
.brand--discover {
  background: url('assets/images/creditCards/discover.png') no-repeat;
  background-size: 100% 100%;
}
.brand--maestro {
  background: url('assets/images/creditCards/maestro.png') no-repeat;
  background-size: 100% 100%;
}
.brand--mastercard {
  background: url('assets/images/creditCards/mastercard.png') no-repeat;
  background-size: 100% 100%;
}
.brand--paypal {
  background: url('assets/images/creditCards/paypal.png') no-repeat;
  background-size: 100% 100%;
}
.brand--skrill {
  background: url('assets/images/creditCards/skrill.png') no-repeat;
  background-size: 100% 100%;
}
.brand--solo {
  background: url('assets/images/creditCards/solo.png') no-repeat;
  background-size: 100% 100%;
}
.brand--switch {
  background: url('assets/images/creditCards/switch.png') no-repeat;
  background-size: 100% 100%;
}
.brand--visa {
  background: url('assets/images/creditCards/visa.png') no-repeat;
  background-size: 100% 100%;
}
.brand--visa_electron {
  background: url('assets/images/creditCards/visa_electron.png') no-repeat;
  background-size: 100% 100%;
}
.brand--western_union {
  background: url('assets/images/creditCards/western_union.png') no-repeat;
  background-size: 100% 100%;
}
.defaultImage {
  background: url('assets/images/creditCards/default.png') no-repeat;
  background-size: 75%;
}
