.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 6px 35px;
  position: relative;
  min-width: 328px;
  width: 328px;
  min-height: 420px;
  height: 465px;
  background: var(--purpletronic-50);
  box-shadow: 10px 10px 35px 8px #9090ae4d;
  border-radius: 17px;
}
.cardDisabled {
  cursor: auto;
  opacity: 0.7;
}

.classDetails {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 15px;
  width: fit-content;
  height: 28px;
  background: white;
  border-radius: 42px;
  font-style: normal;
  font-weight: 800;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: var(--purpletronic);
  margin: 20px 20px 0 0;
  right: 0;
  top: 0;
}

.verticalDivider {
  border-left: 1.5px solid var(--purpletronic);
  height: 15px;
}

.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: auto;
  vertical-align: bottom;
  width: 100%;
}

.img {
  min-width: 144px;
  min-height: 142px;
  max-width: 144px;
  max-height: 142px;
  width: 144px;
  height: 142px;
  border: 5px solid var(--purpletronic);
  background: var(--deepblue);
  border-radius: 81px;
  object-fit: cover;
}

.name {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--font-size-18);
  text-align: center;
  letter-spacing: var(--letter-spacing-4);
  word-wrap: break-word;
  color: var(--purpletronic);
}

.vector {
  height: 18px;
  width: 79px;
}

.subjects {
  display: inline-block;
  width: 80%;
  height: 80px;
  overflow-y: auto;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: center;
}

.subject {
  padding: 0 10px;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  border: 0;
  margin: 2px;
  min-width: 125px;
  min-height: 28px;
  background: var(--purpletronic-30);
  border-radius: 42px;
}

.subjectName {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--font-size-14);
  text-align: center;
  color: var(--purpletronic);
}
