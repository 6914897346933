.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  height: 100vh;
  min-width: 500px;
  min-height: 100vh;
  background: var(--purpletronic);
  mix-blend-mode: normal;
  position: relative;
  overflow-y: auto;
}

.classfyLogo {
  left: 0;
  top: 0;
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.images {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 470px;
  height: 175px;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 13px;
  right: 6px;
}
.images .spinner {
  display: grid;
  margin: 0 auto;
}

.innerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
  width: 50%;
}

.innerContainer button {
  width: fit-content !important;
}

.form {
  display: grid;
  width: 100%;
  max-width: 280px;
  gap: 0.5rem;
}

.h2 {
  text-align: center;
  color: var(--bright) !important;
}
.h2 span {
  color: var(--seadapted);
}

.paragraph {
  color: var(--bright);
}
.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.8rem 0 0;
}

.tag {
  background: var(--seadapted) !important;
}

@media (width < 800px) {
  .main {
    min-width: 250px;
    padding: 0 1rem 1.5rem 1rem;
    gap: 1rem;
  }
  .classfyLogo {
    padding: 1rem;
    position: sticky;
    display: flex;
    height: 70px;
  }
  .images {
    width: 250px;
    height: 100px;
  }
  .innerContainer {
    width: 100%;
  }
}
