.card {
  width: 266px;
  min-width: 266px;
  height: 250px;
  background: var(--bright);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  padding: 35px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.title {
  font-weight: bold;
  text-align: center;
}
.textInfo {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: center;
  margin-bottom: 5px;
  width: 100%;
}
.textInfo p {
  color: var(--dark-grey-40);
  font-weight: bold;
}

.vector {
  width: 50px;
  height: 16px;
}
