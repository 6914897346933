.main {
  width: 100%;
  height: 100vh;
  min-width: 500px;
  min-height: 100vh;
  overflow-y: auto;
  background: var(--bright);
}

@media (width < 800px) {
  .main {
    min-width: 220px;
  }
}
