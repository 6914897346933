.lightbulb {
  display: grid;
  place-items: center;
  width: 53px;
  height: 53px;
}
.innerContainer {
  width: 50px;
  height: 50px;
  background: var(--purpletronic);
  padding: 10px;
  border-radius: 60px;
  cursor: pointer;
}
.innerContainer[data-status='true'] {
  opacity: 0.7;
  cursor: not-allowed;
}
.innerContainer:active {
  width: 52px;
  height: 52px;
}

.on {
  opacity: 0.7;
  cursor: not-allowed;
}
.on:active {
  width: 50px;
  height: 50px;
}
