.calendar {
  min-width: fit-content;
  width: 100%;
  border: 1px solid var(--dadada);
  border-radius: 8px;
  padding: 0.5rem;
  background: var(--bright);
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
}

.actions .title {
  color: var(--purpletronic);
  text-align: center;
  width: 100%;
}
.actions .chevron {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}
.actions .chevronDisabled {
  cursor: not-allowed;
}

.header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.dayHeader {
  color: var(--seadapted);
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
}

.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day {
  width: 1.5rem;
  height: 1.5rem;
  font-size: var(--font-size-13);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--text-color-menu-item);
}

.day:hover {
  background: var(--seadapted-50);
  color: var(--bright);
  cursor: pointer;
  border-radius: 16px;
  transition: background 0.3s ease;
}

.today {
  font-weight: bold;
  color: var(--seadapted);
}
.dayOutOfRange {
  color: var(--disabled-color-24);
}

.selectedDate {
  font-weight: bold;
  background: var(--seadapted);
  color: var(--bright);
  border-radius: 16px;
}

.dayDisabled {
  color: var(--disabled-color) !important;
  cursor: not-allowed;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: var(--error-color);
  border-radius: 50%;
  position: absolute;
  top: 2.5px;
  right: 1px;
}
