.modal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 35px;
  position: relative;
}

.modal .alignedSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.modal .label {
  text-transform: capitalize;
  color: var(--dark-grey-40);
}

.modal .closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.modal .disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.modal .hourRangeInfo {
  cursor: pointer;
}

.modal .hourRangeInfo .pencilIcon {
  margin-left: 5px;
  width: 20px;
  height: 20px;
}

.modal .cancellationAlert {
  color: var(--sandground);
}

/* modal - step1 */
.modal .paragraph {
  color: var(--purpletronic);
}

.modal .cancelActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

/* modal - step2 */
.modal.step2 {
  padding: 50px 80px;
}

/* modal - step3 */
.modal.step3 {
  padding: 35px 50px 30px;
  min-height: 345px;
  width: 600px;
}

.modal .dateSection {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.modal .dateSection .select {
  width: 100%;
}

.modal .newAttendanceInfo {
  text-align: center;
  color: var(--purpletronic);
}
.modal .newAttendanceInfo span {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--font-size-25);
}
.modal .newAttendanceError {
  text-align: center;
  color: var(--sandground);
}

.modal .step3Actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 50px;
  margin-top: auto;
  vertical-align: bottom;
}

/* modal - step4 */
.modal.step4 {
  padding: 50px 80px;
}

.modal .subjectBold {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--font-size-25);
}

.modal .step4Paragraph {
  text-transform: capitalize;
  color: var(--purpletronic);
}
