/* Heading */
.h1 {
  font-style: normal;
  font-weight: 800;
  font-size: var(--font-size-32);
  line-height: var(--line-heights-35);
  letter-spacing: var(--letter-spacing-5);
  color: var(--purpletronic);
}

.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-28);
  line-height: var(--line-heights-30);
  letter-spacing: var(--letter-spacing-3);
  color: var(--purpletronic);
}

.h3 {
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-22);
  line-height: var(--line-heights-28);
  color: var(--purpletronic);
}

.h4 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-20);
  line-height: var(--line-heights-25);
  color: var(--purpletronic);
}
.h4Light {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-25);
  color: var(--purpletronic);
}

.h5 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-22);
  color: var(--purpletronic);
}

/* Paragraph */
.body1 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-19);
  color: var(--grey);
}
.body1Bold {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-19);
  color: var(--grey);
}
.body2 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-14);
  line-height: var(--line-heights-16);
  color: var(--grey);
}
.body2Bold {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-14);
  line-height: var(--line-heights-35);
  color: var(--grey);
}

/* Label */
.label {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-14);
  color: var(--grey);
}
