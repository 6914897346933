.section {
  width: 95%;
  height: 100%;
  min-width: 620px;
}

.header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  margin-bottom: 40px;
}

.details {
  border-bottom: 1px solid var(--dadada);
}

.summary {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}
.selected {
  background: var(--purpletronic-30);
}
.summary::marker {
  display: none;
}
.summary::-webkit-details-marker {
  display: none;
}

.summaryRow {
  display: grid;
  align-items: center;
  grid-template-columns: auto 2fr 4fr auto;
  gap: 12px;
  width: 100%;
  min-height: 45px;
  cursor: pointer;
  padding: 10px;
}

.summaryTitle {
  color: var(--purpletronic);
}

.summaryDescription {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
  color: var(--dark-grey-40);
}

.summaryLastColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.summaryDate {
  color: var(--dark-grey-40);
}

.summaryIcon {
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.content {
  padding: 15px;
}

.contentParagraph {
  color: var(--dark-grey-40);
}

.listActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.listActions .icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
