/* Common */
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-width: 500px;
  min-height: 100vh;
  overflow-y: auto;
  background: var(--purpletronic);
  mix-blend-mode: normal;
}

.classfyLogo {
  padding-left: 6em;
  padding-top: 3.3em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-height: 100vh;
  padding-bottom: 10px;
}
.images {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 420px;
  height: 252px;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 13px;
  right: 6px;
}

.innerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.innerContainer .commonInput {
  width: 583px;
  height: 53.79px;
  background: white;
  border-radius: 34px;
  border: 1px solid var(--purpletronic);
  box-sizing: border-box;
  padding: 12px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  color: var(--shade);
}

.commonInput:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic-50);
}
.commonInput::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

/* step 0 */
.text {
  width: 620px;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-34);
  line-height: var(--line-heights-40);
  letter-spacing: var(--letter-spacing-4);
  color: white;
}

.text .accent {
  color: var(--sandground);
}
.innerContainer button {
  width: 160px !important;
}

/* step1, step2, step3 common */
.actions {
  display: inline-flex;
  justify-content: space-between;
  width: 583px;
}

.innerContainer .actions button {
  width: 160px !important;
}

/* step 2 */
.studentName {
  font-style: normal;
  font-weight: 600;
  font-size: var(--font-size-28);
  line-height: var(--font-size-30);
  letter-spacing: var(--letter-spacing-1);
  color: white;
  text-align: center;
}

/* step 3 */
.selectWrapper {
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 583px;
}

.innerContainer .textarea {
  width: 583px;
  max-width: 583px;
  background: white;
  border-radius: 10px;
  border: 1px solid var(--purpletronic);
  box-sizing: border-box;
  padding: 12px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--font-size-16);
  color: var(--shade);
}

.textarea:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic-50);
}
.textarea::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

/* step 4 */

.step4 {
  margin-top: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.step4H2 {
  color: var(--bright);
}
.step4Text {
  color: var(--seadapted) !important;
  font-weight: 700 !important;
  font-size: var(--font-size-25) !important;
  line-height: var(--font-size-40) !important;
  text-align: center !important;
  letter-spacing: var(--letter-spacing-4) !important;
}

.resume {
  width: 420px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 25px;
  margin: 10px 0;
  background: var(--purpletronic);
  border: 2px solid var(--bright);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 17px;
  padding: 18px;
}
.resume .header {
  width: 100%;
}
.resume .academicBackground {
  text-align: center;
  float: right;
  background: var(--bright);
  border-radius: 42px;
  padding: 3px 16px;
}

.resume .academicBackground span {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--font-size-14);
  color: var(--purpletronic);
}
.resume .resumeText {
  width: 100%;
  word-wrap: break-word;
  color: var(--bright);
  font-weight: 600;
}
.resume .vector {
  height: 20px;
  width: 80px;
  border-radius: 0px;
}
.resume .subjects {
  display: inline-block;
  width: 100%;
  height: 97px;
  overflow-y: auto;
  margin: 0 auto;
  text-align: center;
}

.resume .subjects .subject {
  padding: 0 10px;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  border: 0;
  margin: 2px;
  min-height: 28px;
  background: var(--seadapted);
  border-radius: 42px;
}

.resume .subjects .subject .subjectName {
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--font-size-14);
  text-align: center;
  color: var(--purpletronic);
}

.step4 button {
  width: 230px !important;
}

/* step 5 */

.step5InnerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.goodbyeTextSection {
  display: grid;
  place-items: center;
  gap: 15px;
}

.goodbyeTextSection .mainGoodbyeText {
  text-align: center;
  color: var(--bright);
}

.goodbyeTextSection .accentText {
  color: var(--seadapted);
}

.goodbyeTextSection .secondaryGoodbyeText {
  color: white;
  text-align: center;
}
.step5InnerContainer button {
  width: 220px !important;
}
