.section {
  height: 100%;
  width: 100%;
}
.card {
  width: fit-content;
  min-width: 450px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
  padding: 20px;
}
.backIcon {
  width: 21px;
  height: 22px;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.proposalName {
  font-weight: 600;
}
.subjectsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.subject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 40px;
}
.subscriptionDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.divider {
  margin: 10px 0;
  border-bottom: 1.5px solid var(--purpletronic);
}
.totalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}
.buttonActions {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 20px;
  margin: 10px 0;
}

.conditionsContainer {
  margin: 20px 0;
  padding: 10px;
}
.conditionText {
  margin: 2px 0;
  font-size: var(--font-size-12);
}
.dateText {
  margin: 6px 9px;
  font-size: var(--font-size-14);
}

/* step1 */
.topSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.subjectSelect {
  width: max(40%, 250px);
}
.calendarSection {
  margin-top: 20px;
  width: 80%;
}
.saveButton {
  margin-top: 20px;
}
.toolbar {
  display: flex;
  align-items: center;
  gap: 20px;
}

.attendanceCalendar {
  width: 95%;
}

/* step2 */
.teacherList {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(337px, 1fr));
  gap: 10px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
}

.teacherInfo {
  position: relative;
}
.teacherInfo .icon {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  background: var(--purpletronic);
  border-radius: 50%;
  animation: move 2s infinite alternate ease-in-out;
}

@keyframes move {
  0% {
    transform: scale(1);
    background-color: var(--purpletronic-30);
  }
  100% {
    transform: scale(1.1);
    background-color: var(--purpletronic-15);
  }
}

.teacherModalInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 25px 35px;
  position: relative;
}
.teacherModalInfo .closeIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
}

.teacherModalInfo .paragraph {
  color: var(--purpletronic);
}
.teacherModalInfo .dayLabel {
  font-weight: 600;
  line-height: var(--font-size-30);
}
.teacherModalInfo .advice {
  width: 100%;
  color: var(--sandground);
  text-align: center;
}

/* TODO */
.contentModal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 50px 75px;
  overflow: auto !important;
}
.bold {
  font-weight: bold;
}

.columns {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}
.leftColumn {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
}

.actions {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
