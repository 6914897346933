.blackboard {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}

.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  display: flex;
  margin-bottom: 6px;
}
.notebookArea {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
  border-radius: 16px 0 0 16px;
  border-right: 0.5px solid var(--text-color-menu-item);
}
.notebookActions {
  display: grid;
  place-items: center;
  width: 17%;
  height: 100%;
  cursor: pointer;
  margin: 0 auto;
}
.notebookChevrons {
  width: 30px;
  height: 30px;
}
.notebookCanvas {
  background: var(--disabled-color-24);
  box-shadow: 0px 0px 35px 0px rgba(144, 144, 174, 0.2);
  height: inherit;
  width: calc(100% - 35%);
  cursor: zoom-in;
}
.notebookCanvasDisabled {
  cursor: not-allowed;
}
.canvas {
  height: 100%;
  width: 40%;
  border-radius: 0 16px 16px 0;
}

.tools {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  top: 0;
  left: 0;
  padding: 8px 24px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: var(--purpletronic-40);
  opacity: 0.94;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.modalWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 100%;
  background: var(--bright);
  box-shadow: 0px 0px 35px 0px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  isolation: isolate;
}
.modalContent {
  border-radius: 26px 26px 0 0;
  overflow-x: auto;
  overflow-y: auto;
}
.zoomPageCanvas {
  width: 1200px;
  height: 1700px;
}

.zoomPageCanvas.adjusted {
  --page-width: calc(100vw - 100px);
  width: var(--page-width);
  height: calc(var(--page-width) * 1.33);
}

.modalActions {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: flex-end;
  border-top: 0.5px solid var(--disabled-color-24);
  border-radius: 0 0 26px 26px;
  padding: 4px;
}
