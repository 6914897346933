.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-width: 500px;
  min-height: 100vh;
  overflow-y: auto;
  background: white;
}

.containerWithMenu {
  display: inline-flex;
}

.lateral {
  width: 250px;
}

.content {
  width: calc(100vw - 250px);
  padding: 0 3px 25px 25px;
}

@media (width < 800px) {
  .main {
    min-width: 220px;
  }
  .lateral {
    width: 190px;
    margin-left: -30px;
  }
  .content {
    width: calc(100vw - 163px);
  }
}
