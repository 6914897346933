.commonContainer {
  padding: 20px 5px;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;
}

.alignedItem {
  align-self: center;
}

.checkIcon {
  height: 12px;
  width: 12px;
}

.audioSource {
  max-width: 500px;
  width: max(100%, 120px);
  margin: 20px auto;
  align-self: center;
}
