.section {
  height: 100%;
  width: 100%;
  position: relative;
}
.header {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
}
.accent {
  color: var(--seadapted);
}
.h4 {
  font-weight: 600;
}
.teacherCardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 465px;
  height: 465px;
  margin-left: 30px;
}

.teacherCardImg {
  margin-top: 0;
}

.teacherCardSubjects {
  height: fit-content;
  margin: 0 0 40px 0;
}

.recommendationSection {
  margin-top: 50px;
}

.recommendationTitle {
  margin-bottom: 4px;
}

.recommendationInnerContainer {
  display: flex;
  padding: 30px 25px;
  gap: 30px;
  overflow-y: hidden;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 550px;
  min-width: 550px;
  height: 371px;
  background: var(--purpletronic-30);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 16px;
  padding: 25px 35px;
}
.contact .paragraph {
  color: var(--purpletronic);
}
.contact .vector {
  height: 18px;
  width: 79px;
}
.contact .qr {
  display: grid;
  place-items: center;
  text-align: center;
  gap: 20px;
  padding: 50px 50px 25px 50px;
  position: relative;
}
.contact .qr .whatsAppNumber {
  color: var(--purpletronic);
}

.cross {
  position: absolute;
  top: 15px;
  right: 15px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.crossDisabled {
  cursor: not-allowed;
}
