.emoticonsBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 232px;
  width: 50px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 36px;
  background: var(--bright);
  position: relative;
}

.iconContainer {
  display: grid;
  place-items: center;
  width: 37px;
  height: 37px;
}
.icon {
  width: 33px;
  height: 33px;
  cursor: pointer;
}
.on {
  transform: scale(1.3);
  transition: transform 0.2s;
}

.icon:active {
  width: 35px;
  height: 35px;
}

.disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
