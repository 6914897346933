.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.carousel {
  width: 100%;
  cursor: grab;
}
.slide {
  padding: 1rem;
}
