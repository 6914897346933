.phoneContainer {
  width: 100% !important;
  height: 45px;
}

.phoneContainer > div > div,
.phoneContainer > div > div:hover,
.phoneContainer > div > div:focus {
  background-color: transparent !important;
}

.phoneContainer > div > ul > li[aria-selected='true'],
.phoneContainer > div > ul > li:hover {
  background-color: var(--bright-color) !important;
}

.phoneInput {
  width: inherit !important;
  height: inherit !important;
  border-radius: 40px !important;
  background: var(--bright);
  border: 1px solid var(--purpletronic-50) !important;
  font-weight: 500;
  font-size: var(--font-size-12) !important;
  line-height: var(--line-heights-16);
  color: var(--shade);
}
.phoneInput:focus-visible,
.phoneInput:focus {
  border-color: transparent !important;
  box-shadow: none !important;
  outline: 0;
  border: 1px solid var(--purpletronic) !important;
}

.phoneInputDisabled,
.phoneInputDisabled:hover,
.phoneInputDisabled:focus-within {
  background: var(--bright-color) !important;
  border: 1px solid var(--disabled-color) !important;
  pointer-events: visible !important;
  cursor: not-allowed !important;
  color: var(--disabled-color-24);
}

.phoneInputError {
  border: 1px solid var(--sandground) !important;
}

.phoneInput::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.phoneButton {
  border-radius: 40px 0 0 40px !important;
  border: 1px solid transparent !important;
}
.phoneButton:focus-within {
  border: 1px solid var(--purpletronic-50) !important;
}

.phoneButtonDisabled,
.phoneButtonDisabled:hover,
.phoneButtonDisabled:focus-within {
  cursor: not-allowed !important;
  color: var(--disabled-color-24) !important;
}

.phoneDropdown {
  width: inherit !important;
  margin-top: 4px !important;
  border: 1px solid var(--disabled-color) !important;
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04),
    0px 20px 20px rgba(44, 39, 56, 0.04) !important;
  border-radius: 6px !important;
}
