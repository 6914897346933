.page1 {
  height: 296.46mm;
  margin: 0;
  position: relative;
  width: 210mm;
  overflow: initial !important;
  font-family: 'Roboto', sans-serif !important;
}
.pagePreview {
  height: 196mm;
  margin: 0;
  position: relative;
  width: 180mm;
  overflow: initial !important;
  font-family: 'Roboto', sans-serif !important;
}
.page2 {
  height: 296.46mm;
  margin: 0;
  position: relative;
  width: 210mm;
  overflow: initial !important;
  font-family: 'Roboto', sans-serif !important;
}

.containerLogo {
  width: 110px;
  height: 110px;
  position: absolute;
  right: 60px;
  top: 0;
}

.classfyLogo {
  width: 110px;
  height: 110px;
}

.mainTitle {
  left: 50px;
  top: 30px;
  right: 160px;
  margin: 0;
  position: absolute;
  color: var(--purpletronic);
}

.listTitle {
  margin: 8px 0;
}

.list {
  list-style-type: none;
  padding-left: 0;
  font-size: var(--font-size-12);
}

.listItem {
  position: relative;
  padding-left: 12px;
  text-align: justify;
  margin: 5px 0;
}

.listItem::before {
  content: '-';
  position: absolute;
  left: 0;
}

.subList {
  list-style-type: none;
  margin: 3px 0;
  font-size: var(--font-size-11);
}

.subListItem {
  position: relative;
  padding-left: 20px;
  text-align: justify;
  margin: 5px 0;
}

.subListItem::before {
  content: '-';
  margin: 0 5px 0 10px;
  position: absolute;
  left: 0;
}
.textDetails {
  font-size: var(--font-size-12);
}

.lineDivider {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 35px 0;
}

.floatingTitle {
  font-weight: 500;
  width: 500px;
  display: inline-block;
  height: 56.5px;
  left: 56px;
  line-height: 56.5px;
  position: absolute;
  text-align: center;
  top: 120px;
  color: white;
  background: var(--purpletronic);
}
.floatingSubtitle {
  font-weight: 500;
  width: 220px;
  display: inline-block;
  height: 56.5px;
  left: 56px;
  line-height: 56.5px;
  position: absolute;
  text-align: left;
  top: 120px;
  padding: 0 10px;
  color: white;
  background: var(--purpletronic);
}
.mainContent {
  display: inline-block;
  font-size: var(--font-size-12);
  position: absolute;
  top: 170px;
  left: 60px;
  right: 60px;
  bottom: 20px;
  margin-top: 15px;
  text-align: justify;
}
.mainContentPreview {
  display: inline-block;
  font-size: var(--font-size-12);
  position: absolute;
  top: 70px;
  text-align: justify;
}
.mainContent2 {
  display: inline-block;
  font-size: var(--font-size-12);
  position: absolute;
  top: 20px;
  left: 60px;
  right: 60px;
  margin-top: 15px;
  text-align: justify;
}
.mainContent2Preview {
  display: inline-block;
  font-size: var(--font-size-12);
  position: absolute;
  top: 20px;
  margin-top: 15px;
  text-align: justify;
}

.secondary {
  margin-bottom: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.signature {
  font-size: var(--font-size-12);
  left: 5px;
  position: absolute;
  right: 30px;
  top: 655px;
}

.signArea {
  font-size: var(--font-size-12);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 725px;
}

.signAreaSection {
  font-size: var(--font-size-12);
  display: inline-block;
  text-align: center;
  width: 33%;
  position: relative;
}

.signAreaSection:after {
  content: ' ';
  position: absolute;
  left: 27px;
  top: 90px;
  bottom: 180px;
  height: 1px;
  width: 75%;
  background-color: var(--dark-grey);
}

.signAreaSection img {
  width: 95%;
}

.footer {
  font-size: var(--font-size-10);
  position: absolute;
  left: 124px;
  right: 30px;
  bottom: -430px;
}
