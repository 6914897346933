.notification {
  position: relative;
  width: 552px;
  min-height: 206px;
  z-index: 300;
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  width: inherit;
  min-height: inherit;
  height: inherit;
  z-index: inherit;
  padding: 33px 20px 20px 55px;
}

/* backgrounds */
.error {
  background: var(--sandground);
}
.success {
  background: var(--seadapted);
}
.info {
  background: var(--accent-color);
}
.warning {
  background: var(--purpletronic);
}

.cross {
  position: absolute;
  height: 30px;
  width: 30px;
  cursor: pointer;
  top: 10px;
  left: 10px;
  z-index: 301;
}

.titleSection {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  gap: 0.5rem;
  color: var(--bright) !important;
}
.titleSection .icon {
  height: 30px;
  width: 30px;
}
.titleSection .title {
  width: calc(100% - 30px);
}

.vector {
  width: 57px;
  height: 13px;
}

.message {
  color: var(--bright) !important;
  font-weight: 600;
}

@media (width < 800px) {
  .notification {
    width: 85vw;
    min-height: 150px;
    align-self: flex-end;
  }

  .innerContainer {
    padding: 15px 10px 10px 40px;
    gap: 0.5rem;
  }

  .cross {
    height: 20px;
    width: 20px;
    top: 5px;
    left: 5px;
  }

  .titleSection .icon {
    height: 20px;
    width: 20px;
  }

  .titleSection .title {
    font-size: var(--font-size-14);
  }

  .vector {
    width: 45px;
    height: 8px;
  }

  .message {
    font-size: var(--font-size-12);
  }
}
