.container {
  min-width: 360px;
  width: 360px;
  min-height: 300px;
  height: 300px;
  background: var(--purpletronic);
  box-shadow: 5px 5px 20px 5px rgba(144, 144, 174, 0.3);
  border-radius: 17px;
  text-align: center;
  display: grid;
  place-items: center;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content button span {
  color: var(--purpletronic) !important;
}

.imageSvg {
  height: 2.25rem;
  width: auto;
}

.title {
  font-weight: 800;
  font-size: var(--font-size-17);
  line-height: var(--line-heights-20);
  letter-spacing: var(--letter-spacing-1);
  text-align: center;
  margin: 5px 0;
  color: var(--seadapted);
  z-index: 1;
}

.subheader {
  display: grid;
  place-items: center;
  gap: 12px;
}

.paragraph,
.teacherName {
  color: var(--bright);
}

.vector {
  width: 40px;
  height: 16px;
}

.classDetails {
  width: fit-content;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 15px;
  background: white;
  border-radius: 42px;
  font-weight: 800;
  font-size: var(--font-size-12);
  color: var(--purpletronic);
}

.verticalDivider {
  border-left: 1.5px solid var(--purpletronic);
  height: 15px;
}

.ratingContainer {
  position: absolute;
  bottom: 1.25rem;
  right: 1.75rem;
}
