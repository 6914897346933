.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 40px 50px 0 0;
}
.dateContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}
.monthSelector,
.yearSelector {
  width: 220px;
}

.photoContainer {
  max-width: 1420px;
  display: flex;
  flex-wrap: wrap;
  margin: 30px auto 0;
  gap: 10px;
}

.photoItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 12px;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: transform 0.3s ease, border-color 0.3s ease;
}

.photoItem:hover {
  transform: scale(1.03);
  border-color: var(--dark-grey-20);
}

.photo {
  width: 100%;
  height: auto;
  max-height: 450px;
  object-fit: contain;
}

.photoDate {
  color: var(--purpletronic);
  font-size: var(--font-size-10);
  font-weight: 700;
}
