.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }
  66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.dot {
  animation: loading 1.5s infinite;
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}
