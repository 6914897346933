.input {
  min-width: 100px;
  width: 100%;
  height: 45px;
  background: var(--bright);
  border-radius: 34px;
  border: 1px solid var(--purpletronic-40);
  box-sizing: border-box;
  padding: 5px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  color: var(--shade);
}

.input:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic);
}
.input::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.disabled {
  background: var(--bright-color);
  border: 1px solid var(--purpletronic-40);
  color: var(--purpletronic-30);
  cursor: not-allowed;
}

.disabled::placeholder {
  color: var(--purpletronic-50);
  opacity: 0.5;
}

.error {
  border: 1px solid var(--sandground);
}
