.section {
  width: calc(100vw - 325px);
  height: calc(100vh - 200px);
  min-width: 700px;
  min-height: max(100%, 500px);
  display: flex;
  padding: 30px 15px;
  align-items: center;
  justify-content: center;
}

.reconnectingContainer {
  width: 100%;
  height: 100%;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  gap: 25px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  padding: 45px;
}

.svg {
  width: 280px;
  height: 230px;
}

.fabIconSection {
  display: flex;
  gap: 1rem;
  position: absolute;
  right: 0;
  bottom: 0;
}
.fabIcon {
  position: relative;
}
