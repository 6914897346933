.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipTitle {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  white-space: nowrap;
  animation: slideIn 0.3s ease-out;
}
.tooltipTitle.--top {
  bottom: 100%;
}
.tooltipTitle.--bottom {
  top: 130%;
}
.tooltipTitle.--left {
  left: -10px;
  top: 0;
  transform: translateX(-100%);
}
.tooltipTitle.--right {
  left: 105%;
  right: 0;
  top: 0;
  transform: translateX(0);
}

.tooltipTrigger {
  cursor: pointer;
  display: inline-block;
}
