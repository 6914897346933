.section {
  width: max(100%, 420px);
  height: 100%;
  margin: 0 auto;
}
.titleContactData {
  text-align: center;
}
.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 450px));
  gap: 35px;
  place-content: center;
  margin-top: 40px;
}
.card {
  max-width: 400px;
  width: 100%;
  padding: 10px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
}
.titleCard {
  text-align: center;
  margin: 25px 0;
}
.cardContent {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.inputContainer {
  width: 350px;
}
.actions {
  width: 350px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
