.container {
  width: 100%;
}
.label {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  color: var(--text-color-item);
  margin: 0 0 7px 2px;
}
.select {
  width: inherit;
  min-width: inherit;
  min-height: 45px !important;
  background: var(--bright);
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  border: 1px solid var(--purpletronic-40);
  border-radius: 34px;
  padding: 5px 20px;
  cursor: pointer !important;
}
.select:hover,
.select:focus-within {
  border: 1px solid var(--purpletronic);
}

.selectDisabled,
.selectDisabled:hover,
.selectDisabled:focus-within {
  background: var(--bright-color);
  border: 1px solid var(--purpletronic-40);
  pointer-events: visible !important;
  cursor: not-allowed !important;
  color: var(--purpletronic-30);
}

.placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  color: var(--dark-color);
}
.placeholderDisabled {
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  mix-blend-mode: normal;
  opacity: 0.24;
}

.chevronBottom {
  width: 30px;
  height: 30px;
}

.menu {
  margin-top: 4px;
  width: inherit;
  background: white;
  border: 1px solid var(--disabled-color);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04),
    0px 20px 20px rgba(44, 39, 56, 0.04);
  border-radius: 6px;
}
.option {
  display: flex !important;
  align-items: center;
  min-height: 44px;
  width: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-12) !important;
  line-height: var(--line-heights-16);
  color: var(--text-color-item);
  padding: 5px 10px 5px 15px;
  cursor: pointer !important;
}
.optionSelected {
  background: var(--seadapted-50);
  font-weight: 500;
  opacity: 0.8;
  color: var(--dark-color);
}

.option:hover {
  background: var(--bright-color);
}
