.container {
  gap: 60px;
  width: 100%;
  min-width: max-content;
  height: 100%;
  user-select: none;
}
.disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

/* actions */

.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.actions {
  padding-top: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  height: 40px;
}

.chevron {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.chevronDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 1px;
  color: var(--purpletronic);
  padding: 0 5px;
  min-width: 170px;
  text-transform: capitalize;
}

.calendar {
  display: flex;
  box-shadow: 0px 3px 68px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 15px;
}

.hourSection {
  text-align: center;
  margin: 0 auto;
  height: 100%;
  min-width: fit-content;
}
.hourSectionTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 30px;
  color: var(--seadapted);
}
.hourSectionTitleToday {
  font-weight: bold;
}
.hourContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.hour {
  padding: 4px 8px;
  margin: 1px;
  width: 121px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: var(--purpletronic);
  cursor: pointer;
}
.hour:hover {
  opacity: 0.8;
  transition: 0.15s;
}
.selectedHour {
  padding: 4px 8px;
  font-weight: 600;
  color: var(--bright);
  background: var(--purpletronic);
  border-radius: 50px;
}
.disabledHour {
  cursor: not-allowed;
  color: var(--dadada);
}
