.section {
  height: 100%;
  width: 100%;
  padding: 5px;
}

.spinner {
  font-weight: 700;
  font-size: var(--font-size-50);
  color: var(--seadapted);
}

.container {
  margin: 30px 0;
}

.details {
  border-bottom: 1px solid var(--dadada);
}

.isOpen {
  background: var(--seadapted-5);
}

.summary {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.summary::marker {
  display: none;
}
.summary::-webkit-details-marker {
  display: none;
}
.summaryHeader,
.summaryRow {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr 2fr 1fr;
  gap: 0.5rem;
  width: 100%;
  min-height: 60px;
  cursor: pointer;
  padding: 10px;
}

.summaryHeader {
  max-height: 40px;
  padding: 20px;
  background: var(--purpletronic-5);
  cursor: auto;
}
.summaryFirstHeaderCell {
  color: var(--purpletronic);
}
.summaryHeaderCell {
  color: var(--purpletronic);
  text-align: center;
}

.summaryTitle {
  color: var(--text-color-item);
}

.summaryPaymentData {
  text-align: center;
}

.placeholder {
  color: var(--dark-grey-40);
  font-style: italic;
}

.promo {
  margin: 2px;
  width: auto;
}

.status[data-status='Pagado'] {
  color: var(--seadapted);
}

.status[data-status='Pendiente'] {
  color: var(--error-color);
}

.status[data-status='Exceso'] {
  color: var(--accent-color);
}

.summaryLastColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.chevronOpen {
  height: 20px;
  width: 20px;
  transform: rotate(180deg);
}

.chevronClose {
  height: 20px;
  width: 20px;
  transform: rotate(0);
}

.summaryContent {
  padding: 2rem;
  background-color: var(--seadapted-5);
}

@media (width < 700px) {
  .section {
    min-width: fit-content;
  }
}
