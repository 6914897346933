.section {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(430px, 1fr));
  gap: 20px;
}
.editableSection {
  width: max(92%, 420px);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.pencilIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.card {
  margin: 25px 30px 30px 0;
  min-height: 140px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  padding: 25px;
}

.bottomLine {
  border-bottom: 1px solid var(--dadada);
  margin-top: 10px;
}

.personalData {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.personalData .profileImageWrapper {
  min-width: 160px;
  min-height: 160px;
  max-width: 160px;
  max-height: 160px;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  pointer-events: none;
  border: 3px solid var(--purpletronic);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.personalData .profileImageWrapper .defaultProfileImage {
  width: 130px;
  height: 130px;
  object-fit: cover;
  border-radius: 50%;
}

.personalData .profileImageWrapper .profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.personalData .rightContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
}
.personalData .rightContent .tag {
  width: fit-content;
  padding: 0 10px;
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}
.documents {
  display: flex;
}
.documentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 50%;
  min-width: 190px;
  padding: 20px 15px 15px;
  justify-content: center;
  text-align: center;
}
.signIcon {
  width: 67px;
  height: 70px;
}
.billing {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  height: 250px;
}
.billing .bottomLine {
  border-bottom: 1px solid var(--dadada);
  margin-top: 10px;
}
.creditCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 15px;
  height: 250px;
  background: var(--purpletronic-30);
}
.creditCard .paragraphColor {
  color: var(--purpletronic);
}
.creditCard .bottomLine {
  border-bottom: 1px solid var(--bright);
  margin-top: 10px;
}
.cardMessage,
.contactMesssage {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.spinner {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.billingMessage {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}
.creditCard .bottomSection {
  display: inline-flex;
  gap: 25px;
}
.creditCardBrandIcon {
  width: 60px;
  height: 40px;
  margin-bottom: 20px;
}
.brand--american_express {
  background: url('../../assets/images/creditCards/american_express.png')
    no-repeat;
  background-size: 100% 100%;
}
.brand--cirrus {
  background: url('../../assets/images/creditCards/cirrus.png') no-repeat;
  background-size: 100% 100%;
}
.brand--delta {
  background: url('../../assets/images/creditCards/delta.png') no-repeat;
  background-size: 100% 100%;
}
.brand--direct_debit {
  background: url('../../assets/images/creditCards/direct_debit.png') no-repeat;
  background-size: 100% 100%;
}
.brand--discover {
  background: url('../../assets/images/creditCards/discover.png') no-repeat;
  background-size: 100% 100%;
}
.brand--maestro {
  background: url('../../assets/images/creditCards/maestro.png') no-repeat;
  background-size: 100% 100%;
}
.brand--mastercard {
  background: url('../../assets/images/creditCards/mastercard.png') no-repeat;
  background-size: 100% 100%;
}
.brand--paypal {
  background: url('../../assets/images/creditCards/paypal.png') no-repeat;
  background-size: 100% 100%;
}
.brand--skrill {
  background: url('../../assets/images/creditCards/skrill.png') no-repeat;
  background-size: 100% 100%;
}
.brand--solo {
  background: url('../../assets/images/creditCards/solo.png') no-repeat;
  background-size: 100% 100%;
}
.brand--switch {
  background: url('../../assets/images/creditCards/switch.png') no-repeat;
  background-size: 100% 100%;
}
.brand--visa {
  background: url('../../assets/images/creditCards/visa.png') no-repeat;
  background-size: 100% 100%;
}
.brand--visa_electron {
  background: url('../../assets/images/creditCards/visa_electron.png') no-repeat;
  background-size: 100% 100%;
}
.brand--western_union {
  background: url('../../assets/images/creditCards/western_union.png') no-repeat;
  background-size: 100% 100%;
}
.defaultImageCard {
  background: url('../../assets/images/creditCards/default.png') no-repeat;
  background-size: 75%;
}

@media (max-width: 1000px) {
  .section {
    grid-template-columns: 1fr;
  }
}
