.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: max(100%, 420px);
  margin: 0 auto;
}

.card {
  width: max(50%, 410px);
  max-width: 440px;
  min-height: 300px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  margin-top: 30px;
  padding: 15px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.passwordRequirementsContainer {
  width: min(400px, 100%);
  height: 95px;
  max-width: 100%;
  max-height: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.alertIcon {
  width: 150px;
  height: 30px;
  cursor: pointer;
}

.passwordRequirementsMessage {
  font-size: var(--font-size-12);
  line-height: var(--font-size-18);
  letter-spacing: var(--letter-spacing-1);
}

.forgotPasswordLabel {
  color: var(--seadapted);
  text-decoration: underline;
  cursor: pointer;
}
.confirmButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  margin-top: 8px;
}
