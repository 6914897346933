.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 100vh;
  background: var(--purpletronic);
  mix-blend-mode: normal;
}

.classfyLogo {
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.videoWrapper {
  min-width: 500px;
  min-height: 300px;
  width: calc(100vw - 60vw);
  height: calc(100vh - 60vh);
  border-radius: 20px;
}

.iframe {
  min-width: inherit;
  min-height: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.bright {
  color: var(--bright);
}
.seadapted {
  color: var(--seadapted);
}
