.section {
  width: max(100%, 420px);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: 0 auto;
}

.card {
  width: min(90%, 500px);
  min-height: 480px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 26px;
  margin-top: 40px;
  padding: 20px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.containerProfileImg {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid var(--purpletronic);
  border-radius: 50%;
}
.profileImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.pencilIconContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.342);
  background-color: var(--bright);
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 15px;
  cursor: pointer;
  padding: 7px;
}

.passwordTitle {
  color: var(--seadapted);
  text-decoration: underline;
  cursor: pointer;
}

.personalInfoContainer,
.contactInfoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.phoneInput {
  width: 100%;
}
.datePicker {
  width: 100%;
}
.academicBackground {
  width: 100%;
}
.enrollmentContianer {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 34px;
  border: 1px solid var(--purpletronic-40);
  background-color: rgba(243, 243, 243, 0.5);
  color: var(--shade);
  opacity: 0.7;
  transition: opacity 0.3s;
  cursor: not-allowed;
}
.enrollmentContianer:hover {
  opacity: 0.5;
}
.enrollment {
  color: var(--purpletronic);
  pointer-events: none;
}
.enrollmentAlert {
  color: var(--sandground);
}
.formActions {
  display: inline-flex;
  justify-content: space-between;
  margin: 15px 0;
  width: 95%;
}

.modalContainer {
  max-width: 425px;
  min-width: 240px;
  max-height: 400px;
  min-height: 380px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  box-sizing: border-box;
}

.profileImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileImageWrapper,
.defaultImageWrapper {
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  border-radius: 50%;
  border: 2px solid var(--dadada);
  box-shadow: 5px 5px 20px rgba(144, 144, 174, 0.2);
}

.modalActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
