.section {
  display: inline-flex;
  gap: 10px;
  padding: 15px 10px 0;
  --mainMinClassroomHeight: 700px;
  --mainMinClassroomWidth: 1150px;
  --blackboardCalculatedHeight: calc(
    max(100vh - 200px, var(--mainMinClassroomHeight) - 200px)
  );
  --blackboardCalculatedWidth: calc(max(100%, var(--mainMinClassroomWidth)));
  width: var(--blackboardCalculatedWidth) !important;
  height: 100%;
  min-height: var(--mainMinClassroomHeight) !important;
}

.leftSide {
  width: 180px;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.classfyLogo {
  width: 130px;
  z-index: 1;
  padding-bottom: 45px;
}

.teacherName {
  color: var(--purpletronic);
  margin: 10px 0;
}
.teacherNameDisabled {
  color: var(--purpletronic-50);
}
.containerChatIcon {
  width: 31px;
  height: 31px;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--error-color);
  position: relative;
}
.chatMessageAlert {
  background: var(--seadapted);
  height: 10px;
  width: 10px;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(144, 144, 174, 0.8),
    0 0 20px rgba(144, 144, 174, 0.5);
}
.chatIcon {
  width: 21px;
  height: 21px;
}
.innerContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 305px);
  height: inherit;
  min-height: inherit;
}

.reconnectingSection {
  display: grid;
  place-items: center;
  width: 100%;
  height: 95%;
  padding-top: 82px;
}

.rightSide {
  width: auto;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  position: relative;
}
.headerProfile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.headerProfile p {
  width: 100%;
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vmin;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.54px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--purpletronic);
}
.headerProfile img {
  width: 33px;
  height: 33px;
  border-radius: 100%;
}
.lightbulbContainer {
  margin-bottom: 10px;
}
.mediaSetup {
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 2px;
  background: var(--seadapted);
  animation: none;
}

.confirmNavigationModal {
  padding: 10px 5px 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.confirmNavigationModal > div {
  display: flex;
  gap: 25px;
}
.chatModalContainer {
  min-width: 240px;
  min-height: 380px;
  max-width: 425px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 28px 8px 8px;
  box-sizing: border-box;
}
