.emoji {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  height: 0.5rem;
  width: 0.5rem;
  box-shadow: 0 0 5px 5px var(--seadapted);
}
.small {
  scale: 1;
}
.medium {
  scale: 2;
}
.large {
  scale: 2.5;
}

.negative {
  box-shadow: 0 0 5px 5px var(--sandground);
}
.tooltipTitle {
  margin-bottom: 0.85rem;
}
