.section {
  height: 100%;
  width: 100%;
  position: relative;
}

.step1TitleSection {
  display: flex;
  align-items: center;
  gap: 20px;
}

.backIcon {
  width: 21px;
  height: 18px;
  cursor: pointer;
}

.h2 {
  color: var(--seadapted);
}
.h3 {
  margin: 20px 0;
  letter-spacing: var(--letter-spacing-0);
}
.h3 strong {
  font-weight: 800;
}
.h4 {
  margin: 25px 0 20px 0;
}
.teacherList {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(337px, 1fr));
  gap: 10px;
  margin-top: 40px;
  justify-content: center;
  justify-items: center;
  align-items: center;
}

/* initial state */
.customSelectContainer {
  width: 360px;
}

.calendarWrapper {
  width: 700px;
  height: 435px;
  margin-top: 15px;
  margin-bottom: 5px;
}

/* @media (max-height: 1000px) {
  .calendarWrapper {
    width: 650px;
    height: 380px;
    margin-top: 35px;
    margin-bottom: 5px;
  }
} */

.toolbar {
  display: flex;
  gap: 20px;
  align-items: center;
}

/* step2 */
.step2 {
  width: 90%;
}
.step2H2 {
  margin: 25px 0 30px 0;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-34);
  line-height: var(--font-size-34);
  letter-spacing: var(--letter-spacing-5);
  color: var(--purpletronic);
}
.step2H2Bold {
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.2rem;
  letter-spacing: var(--letter-spacing-5);
  color: var(--purpletronic);
}

.step2SelectedTeacher {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 30px;
  width: 100%;
}

/* step3 */
.step3H5 {
  font-weight: 700;
  font-size: var(--font-size-16);
  line-height: var(--line-heights-22);
  color: var(--dark-grey-40);
}
.secondaryOptions {
  display: flex;
  padding: 25px 35px 45px;
  gap: 20px;
  overflow-x: auto;
}
.verticalTeacherContainer {
  min-width: 190px;
  width: 190px;
  min-height: 300px;
  height: 300px;
  background: var(--purpletronic-50);
  padding: 0 6px 35px;
}
.verticalTeacherInnerContainer {
  gap: 20px;
}
.verticalTeacherImg {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  width: 80px;
  height: 80px;
  border: 3px solid var(--purpletronic);
}
.verticalTeacherName {
  font-weight: 600;
  font-size: var(--font-size-14);
  line-height: var(--font-size-18);
  letter-spacing: var(--letter-spacing-4);
  color: var(--purpletronic);
}
.verticalTeacherVector {
  height: 18px;
  width: 50px;
}
.verticalTeacherSubjects {
  display: none;
}

/* modal */
.modalContent {
  max-width: 400px;
  padding: 5px;
}
.titleModal {
  color: var(--purpletronic);
}

.modalParagraph {
  margin-top: 15px;
  font-size: var(--font-size-14);
  line-height: var(--line-heights-20);
  letter-spacing: var(--letter-spacing-0);
}
