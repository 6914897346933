.recommendation {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 22px;
  width: 80%;
  margin-bottom: 40px;
}

.recommendationActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.subjectConfigRow {
  display: grid;
  grid-template-columns: 0.75fr auto;
  align-items: center;
  gap: 55px;
  padding: 5px;
}

.subjectSelect {
  width: 450px;
}

.subjectNameLabel {
  justify-self: center;
  display: flex;
  min-width: 136px;
  min-height: 28px;
  padding: 7px 35px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 42px;
  background: var(--purpletronic);
  color: var(--bright);
  text-align: center;
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: 700;
  position: relative;
}
.subjectTooltip {
  display: none;
  background: var(--seadapted);
  color: var(--bright);
  text-align: center;
  border-radius: 4px;
  padding: 8px;
  z-index: 1;
  bottom: calc(100% + 12px);
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: var(--bright);
  text-align: center;
  font-size: var(--font-size-12);
  font-style: normal;
  font-weight: 700;
  position: absolute;
}
.subjectTooltip:after {
  content: '';
  position: absolute;
  left: calc(50% - 8px);
  bottom: 0;
  margin-bottom: -7px;
  border-top: 8px solid var(--seadapted);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.subjectNameLabel:hover .subjectTooltip {
  display: block;
}

.subjectOptions {
  display: flex;
  align-items: center;
}

.removeIcon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  margin-left: 35px;
}
