.menuIcon {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  cursor: pointer;
  margin-right: 0.75rem;
}

.closeIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 16px;
  background: var(--dark-grey-40);
  transition: background 0.3s ease;
}

.rightMenu {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  border-left: 1px solid var(--dadada);
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  height: 103%;
}

.rightMenuHide {
  display: none;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.75rem;
}
.title {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
.title span {
  color: var(--dark-grey-40);
  font-size: var(--font-size-14);
}

.historyList {
  flex: 1;
  max-height: calc(100vh - 380px);
  overflow-y: auto;
}

.questionItem {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: inherit;
  min-width: inherit;
  max-width: inherit;
  margin-bottom: 8px;
  background: var(--bright);
  border: 1px solid var(--dadada);
  border-radius: 8px;
  transition: box-shadow 0.2s;
}

.questionItem:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.questionText {
  display: inline-block;
  font-size: var(--font-size-14);
  font-weight: bold;
  color: var(--dark-grey);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.questionResponse {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  box-orient: vertical;
  line-clamp: 2;
  overflow: hidden;
  font-size: var(--font-size-12);
  color: var(--text-color-menu-item);
  margin-top: 5px;
}

.actions {
  margin-top: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
}

.hour {
  font-size: var(--font-size-12);
  color: var(--text-color-menu-item);
}

.actionButton {
  padding: 4px 8px;
  font-size: 12px;
  color: var(--bright);
  background: var(--purpletronic);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.actionButton:hover {
  background: var(--seadapted);
}

.calendar {
  padding-top: 1rem;
}
