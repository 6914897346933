.button {
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  cursor: pointer;
  border: 0;
}

.tiny {
  min-width: 115px;
  height: 40px;
  padding: 7px 12px;
  border-radius: 42px;
}

.small {
  min-width: 126px;
  height: 40px;
  padding: 7px 12px;
  border-radius: 42px;
}

.medium {
  min-width: 140px;
  height: 48px;
  padding: 7px 12px;
  border-radius: 55px;
}

.large {
  min-width: 220px;
  height: 56px;
  padding: 7px 12px;
  border-radius: 68px;
}
/* type */
.accent-primary {
  background: var(--purpletronic);
}
.accent-secondary {
  background: var(--seadapted);
}
.accent-tertiary {
  background: var(--bright);
}
.bright {
  background: white;
}
.ghost {
  background: transparent;
}
.primary,
.secondary,
.tertiary,
.warning {
  background: transparent;
}

/* size-type */
.tiny-primary {
  border: 2px solid var(--purpletronic);
}
.tiny-secondary {
  border: 2px solid var(--seadapted);
}
.tiny-tertiary {
  border: 2px solid var(--bright);
}
.tiny-warning {
  border: 2px solid var(--sandground);
}

.small-primary {
  border: 2px solid var(--purpletronic);
}
.small-secondary {
  border: 2px solid var(--seadapted);
}
.small-tertiary {
  border: 2px solid var(--bright);
}
.small-warning {
  border: 2px solid var(--sandground);
}

.medium-primary {
  border: 2px solid var(--purpletronic);
}
.medium-secondary {
  border: 2px solid var(--seadapted);
}
.medium-tertiary {
  border: 2px solid var(--bright);
}
.medium-warning {
  border: 2px solid var(--sandground);
}

.large-primary {
  border: 3px solid var(--purpletronic);
}
.large-secondary {
  border: 3px solid var(--seadapted);
}
.large-tertiary {
  border: 3px solid var(--bright);
}
.large-warning {
  border: 3px solid var(--sandground);
}

.tiny-accent-primary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.tiny-accent-primary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.small-accent-primary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.small-accent-primary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.medium-accent-primary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.medium-accent-primary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}
.large-accent-primary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.large-accent-primary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}

.tiny-accent-secondary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.tiny-accent-secondary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.small-accent-secondary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.small-accent-secondary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.medium-accent-secondary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.medium-accent-secondary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}
.large-accent-secondary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.large-accent-secondary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}

.tiny-accent-tertiary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.tiny-accent-tertiary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.small-accent-tertiary {
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.08),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.small-accent-tertiary:hover {
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.08),
    0px 12px 24px rgba(44, 39, 56, 0.16);
}
.medium-accent-tertiary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.medium-accent-tertiary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}
.large-accent-tertiary {
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.large-accent-tertiary:hover {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.08),
    0px 24px 48px rgba(44, 39, 56, 0.16);
}

.small-bright {
  border: 1px solid var(--purpletronic);
  box-shadow: 0px 1px 2px rgba(44, 39, 56, 0.0001),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.small-bright:hover {
  border: 0;
  box-shadow: 0px 6px 12px rgba(44, 39, 56, 0.04),
    0px 12px 24px rgba(44, 39, 56, 0.08);
}
.small-bright:active {
  border: 2px solid var(--seadapted);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.medium-bright {
  border: 1px solid var(--purpletronic);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.medium-bright:hover {
  border: 0;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.04),
    0px 24px 48px rgba(44, 39, 56, 0.08);
}
.medium-bright:active {
  border: 2px solid var(--seadapted);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}
.large-bright {
  border: 1px solid var(--purpletronic-50);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 4px 8px rgba(44, 39, 56, 0.08);
}
.large-bright:hover {
  border: 0;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.04),
    0px 24px 48px rgba(44, 39, 56, 0.08);
}
.large-bright:active {
  border: 2px solid var(--seadapted);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.0001),
    0px 2px 4px rgba(44, 39, 56, 0.08);
}

.small-ghost {
  border: 2px solid white;
}
.small-ghost:hover {
  border: 2px solid var(--purpletronic-50);
}
.small-ghost:active {
  border: 2px solid var(--seadapted);
}
.medium-ghost {
  border: 2px solid white;
}
.medium-ghost:hover {
  border: 2px solid var(--purpletronic-50);
}
.medium-ghost:active {
  border: 2px solid var(--seadapted);
}
.large-ghost {
  border: 2px solid white;
}
.large-ghost:hover {
  border: 2px solid var(--seadapted);
}
.large-ghost:active {
  border: 2px solid var(--sandground);
}

/* disabled */
.tiny-primary-disabled {
  border: 2px solid var(--purpletronic-30);
  cursor: not-allowed;
}
.tiny-secondary-disabled {
  border: 2px solid var(--seadapted-50);
  cursor: not-allowed;
}
.tiny-tertiary-disabled {
  border: 2px solid var(--bright-50);
  cursor: not-allowed;
}
.tiny-warning-disabled {
  border: 2px solid var(--sandground-50);
  cursor: not-allowed;
}

.small-primary-disabled {
  border: 2px solid var(--purpletronic-30);
  cursor: not-allowed;
}
.small-secondary-disabled {
  border: 2px solid var(--seadapted-50);
  cursor: not-allowed;
}
.small-tertiary-disabled {
  border: 2px solid var(--bright-50);
  cursor: not-allowed;
}
.small-warning-disabled {
  border: 2px solid var(--sandground-50);
  cursor: not-allowed;
}

.medium-primary-disabled {
  border: 2px solid var(--purpletronic-30);
  cursor: not-allowed;
}
.medium-secondary-disabled {
  border: 2px solid var(--seadapted-50);
  cursor: not-allowed;
}
.medium-tertiary-disabled {
  border: 2px solid var(--bright-50);
  cursor: not-allowed;
}
.medium-warning-disabled {
  border: 2px solid var(--sandground-50);
  cursor: not-allowed;
}

.large-primary-disabled {
  border: 3px solid var(--purpletronic-30);
  cursor: not-allowed;
}
.large-secondary-disabled {
  border: 3px solid var(--seadapted-50);
  cursor: not-allowed;
}
.large-tertiary-disabled {
  border: 3px solid var(--bright-50);
  cursor: not-allowed;
}
.large-warning-disabled {
  border: 3px solid var(--sandground-50);
  cursor: not-allowed;
}

.tiny-accent-primary-disabled {
  background: var(--purpletronic-30);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.small-accent-primary-disabled {
  background: var(--purpletronic-30);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.medium-accent-primary-disabled {
  background: var(--purpletronic-30);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.large-accent-primary-disabled {
  background: var(--purpletronic-30);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}

.tiny-accent-secondary-disabled {
  background: var(--seadapted-50);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.small-accent-secondary-disabled {
  background: var(--seadapted-50);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.medium-accent-secondary-disabled {
  background: var(--seadapted-50);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.large-accent-secondary-disabled {
  background: var(--seadapted-50);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}

.small-bright-disabled {
  background: var(--bright-color);
  border: 1px solid var(--disabled-color);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.medium-bright-disabled {
  background: var(--bright-color);
  border: 1px solid var(--disabled-color);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.large-bright-disabled {
  background: var(--bright-color);
  border: 1px solid var(--disabled-color);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.small-ghost-disabled {
  border: 2px solid var(--disabled-color-24);
  cursor: not-allowed;
}
.medium-ghost-disabled {
  border: 2px solid var(--disabled-color-24);
  cursor: not-allowed;
}
.large-ghost-disabled {
  border: 2px solid rgb(255, 255, 255, 0.5);
  cursor: not-allowed;
}
.tiny-accent-tertiary-disabled {
  background: var(--bright-50);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.small-accent-tertiary-disabled {
  background: var(--bright-50);
  box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.medium-accent-tertiary-disabled {
  background: var(--bright-50);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}
.large-accent-tertiary-disabled {
  background: var(--bright-50);
  box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.08);
  cursor: not-allowed;
}

/*--- typography --- */
.span {
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

/* size */
.tiny span {
  font-size: var(--font-size-12);
  line-height: var(--line-heights-14);
}
.small span {
  font-size: var(--font-size-14);
  line-height: var(--line-heights-14);
}
.medium span {
  font-size: var(--font-size-16);
  line-height: var(--line-heights-16);
}
.large span {
  font-size: var(--font-size-22);
  line-height: var(--line-heights-24);
}

/* specific */
.tiny-primary span {
  color: var(--purpletronic);
}
.tiny-primary-disabled span {
  color: var(--purpletronic-30);
}

.tiny-secondary span {
  color: var(--seadapted);
}
.tiny-secondary-disabled span {
  color: var(--seadapted-50);
}

.tiny-tertiary span {
  color: var(--bright);
}
.tiny-tertiary-disabled span {
  color: var(--bright-50);
}

.tiny-warning span {
  color: var(--sandground);
}
.tiny-warning-disabled span {
  color: var(--sandground-50);
}

.small-primary span {
  color: var(--purpletronic);
}
.small-primary-disabled span {
  color: var(--purpletronic-30);
}

.small-secondary span {
  color: var(--seadapted);
}
.small-secondary-disabled span {
  color: var(--seadapted-50);
}

.small-tertiary span {
  color: var(--bright);
}
.small-tertiary-disabled span {
  color: var(--bright-50);
}

.small-warning span {
  color: var(--sandground);
}
.small-warning-disabled span {
  color: var(--sandground-50);
}

.medium-primary span {
  color: var(--purpletronic);
}
.medium-primary-disabled span {
  color: var(--purpletronic-30);
}

.medium-secondary span {
  color: var(--seadapted);
}
.medium-secondary-disabled span {
  color: var(--seadapted-50);
}

.medium-tertiary span {
  color: var(--bright);
}
.medium-tertiary-disabled span {
  color: var(--bright-50);
}

.medium-warning span {
  color: var(--sandground);
}
.medium-warning-disabled span {
  color: var(--sandground-50);
}

.large-primary span {
  color: var(--purpletronic);
}
.large-primary-disabled span {
  color: var(--purpletronic-30);
}

.large-secondary span {
  color: var(--seadapted);
}
.large-secondary-disabled span {
  color: var(--seadapted-50);
}

.large-tertiary span {
  color: var(--bright);
}
.large-tertiary-disabled span {
  color: var(--bright-50);
}

.large-warning span {
  color: var(--sandground);
}
.large-warning-disabled span {
  color: var(--sandground-50);
}

.tiny-accent-primary span {
  color: var(--bright);
}
.tiny-accent-primary-disabled span {
  color: var(--bright);
}
.small-accent-primary span {
  color: var(--bright);
}
.small-accent-primary-disabled span {
  color: var(--bright);
}
.medium-accent-primary span {
  color: var(--bright);
}
.medium-accent-primary-disabled span {
  color: var(--bright);
}
.large-accent-primary span {
  color: var(--bright);
}
.large-accent-primary-disabled span {
  color: var(--bright);
}

.tiny-accent-secondary span {
  color: var(--bright);
}
.tiny-accent-secondary-disabled span {
  color: var(--bright);
}
.small-accent-secondary span {
  color: var(--bright);
}
.small-accent-secondary-disabled span {
  color: var(--bright);
}
.medium-accent-secondary span {
  color: var(--bright);
}
.medium-accent-secondary-disabled span {
  color: var(--bright);
}
.large-accent-secondary span {
  color: var(--bright);
}
.large-accent-secondary-disabled span {
  color: var(--bright);
}

.tiny-accent-tertiary span {
  color: var(--purpletronic);
}
.tiny-accent-tertiary-disabled span {
  color: var(--purpletronic);
}
.small-accent-tertiary span {
  color: var(--purpletronic);
}
.small-accent-tertiary-disabled span {
  color: var(--purpletronic);
}
.medium-accent-tertiary span {
  color: var(--purpletronic);
}
.medium-accent-tertiary-disabled span {
  color: var(--purpletronic);
}
.large-accent-tertiary span {
  color: var(--purpletronic);
}
.large-accent-tertiary-disabled span {
  color: var(--purpletronic);
}

.small-bright span {
  color: var(--purpletronic);
}
.small-bright:active span {
  color: var(--seadapted);
}
.small-bright-disabled span {
  color: var(--dark-color);
  mix-blend-mode: normal;
  opacity: 0.24;
}
.medium-bright span {
  color: var(--purpletronic);
}
.medium-bright:active span {
  color: var(--seadapted);
}
.medium-bright-disabled span {
  color: var(--dark-color);
  mix-blend-mode: normal;
  opacity: 0.24;
}
.large-bright span {
  color: var(--purpletronic);
}
.large-bright:active span {
  color: var(--seadapted);
}
.large-bright-disabled span {
  color: var(--dark-color);
  mix-blend-mode: normal;
  opacity: 0.24;
}

.small-ghost span {
  color: white;
}
.small-ghost:hover span {
  color: var(--purpletronic-50);
}
.small-ghost:active span {
  color: var(--seadapted);
}
.small-ghost-disabled span {
  color: var(--dark-color);
  mix-blend-mode: normal;
  opacity: 0.24;
}
.medium-ghost span {
  color: white;
}
.medium-ghost:hover span {
  color: var(--purpletronic-50);
}
.medium-ghost:active span {
  color: var(--seadapted);
}
.medium-ghost-disabled span {
  color: var(--dark-color);
  mix-blend-mode: normal;
  opacity: 0.24;
}
.large-ghost span {
  color: white;
}
.large-ghost:hover span {
  color: var(--seadapted);
}
.large-ghost:active span {
  color: var(--sandground);
}
.large-ghost-disabled span {
  color: rgba(255, 255, 255, 0.5);
  mix-blend-mode: normal;
}
