.modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 35px;
  position: relative;
  max-width: 525px;
}
.h2 {
  color: var(--purpletronic);
}
