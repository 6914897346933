.section {
  min-width: 700px;
}

.card {
  height: calc(100vh - 270px);
  overflow-y: auto;
}
.firstStepContent {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 35px auto 0 auto;
  justify-content: center;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  padding: 35px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.firstStepContent h2 {
  position: relative;
  display: block;
  top: 0px;
  left: 0;
  margin: 0 auto;
}
.firstStepContent ol {
  margin-top: 25px;
}
.thirdStep {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  min-height: calc(100vh - 350px);
  width: 50%;
  margin: 45px auto;
}
.buttonContainer {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  margin-top: 50px;
}
.modalContent {
  width: 380px;
  padding: 10px;
  text-align: center;
}

.modalParagraph {
  margin-top: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-16);
  line-height: var(--font-size-30);
  color: var(--grey);
}
