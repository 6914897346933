.zoomSlider {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  height: fit-content;
  width: fit-content;
}
.disabled {
  opacity: 0.7;
  cursor: not-allowed !important;
}
.zoomIn,
.zoomOut {
  font-style: normal;
  font-weight: 300;
  font-size: 29px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.54px;
  color: var(--purpletronic);
}
.inputContainer {
  position: relative;
}

.inputContainer span {
  position: absolute;
  top: -2.2em;
  width: auto;
  height: 24px;
  font-size: var(--font-size-12);
  font-weight: 900;
  line-height: var(--font-size-24);
  letter-spacing: 0.5px;
  text-align: center;
  background: var(--purpletronic);
  color: var(--seadapted);
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 3px;
  padding: 0 5px;
}
.inputContainer span:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid var(--purpletronic);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}

.slider {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 1em;
  width: 180px;
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 1em + var(--ratio) * (100% - 1em));
  outline: none;
  background: transparent;
  cursor: pointer;
}

.slider:focus {
  outline: none;
}

/*webkit*/
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 53px;
  background: var(--bright);
  border: 1px solid var(--purpletronic);
  margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - 1em * 0.5);
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  height: max(calc(1em - 1px - 1px), 0px);
  box-shadow: none;
  border-radius: 1em;
  background: linear-gradient(
        90deg,
        var(--purpletronic) 43.67%,
        var(--seadapted) 67.47%
      )
      0 / var(--sx) 100% no-repeat,
    var(--disabled-color);
}

.slider::-webkit-slider-thumb:hover {
  background: var(--purpletronic);
}

.slider::-webkit-slider-thumb:active {
  background: var(--purpletronic);
}

/*mozilla*/
.slider::-moz-range-thumb {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 53px;
  background: var(--bright);
  border: 1px solid var(--purpletronic);
  margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - 1em * 0.5);
  cursor: pointer;
}

.slider::-moz-range-track {
  appearance: none;
  height: max(calc(1em - 1px - 1px), 0px);
  box-shadow: none;
  border-radius: 1em;
  background: linear-gradient(
        90deg,
        var(--purpletronic) 43.67%,
        var(--seadapted) 67.47%
      )
      0 / var(--sx) 100% no-repeat,
    var(--disabled-color);
}

.slider::-moz-range-thumb:hover {
  background: var(--purpletronic);
}

.slider::-moz-range-thumb:active {
  background: var(--purpletronic);
}

/*ms*/
.slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

.slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

.slider::-ms-thumb {
  appearance: none;
  width: 1em;
  height: 1em;
  border-radius: 53px;
  background: var(--bright);
  border: 1px solid var(--purpletronic);
  margin-top: calc(max((1em - 1px - 1px) * 0.5, 0px) - 1em * 0.5);
  cursor: pointer;
}

.slider::-ms-track {
  appearance: none;
  height: max(calc(1em - 1px - 1px), 0px);
  box-shadow: none;
  border-radius: 53px;
  background: linear-gradient(
        90deg,
        var(--purpletronic) 43.67%,
        var(--seadapted) 67.47%
      )
      0 / var(--sx) 100% no-repeat,
    var(--disabled-color);
}

.slider::-ms-thumb:hover {
  background: var(--purpletronic);
}

.slider::-ms-thumb:active {
  background: var(--purpletronic);
}
