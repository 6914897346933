.header {
  width: 100%;
  height: 80px;
  background: var(--bright);
  display: flex;
  align-items: center;
  position: relative;
}

.classfyLogo {
  padding-left: 50px;
  padding-right: 30px;
  width: 200px;
  z-index: 1;
}

.right {
  position: absolute;
  right: 0;
  padding-right: 50px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.userInfo {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.userInfo p {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 0.54px;

  color: var(--purpletronic);
}
.userInfo img {
  width: 33px;
  height: 33px;
  border-radius: 100%;
}

.menuWrapper {
  position: relative;
}

.chevronBottom {
  height: 30px;
  width: 20px;
  margin-top: 5px;
  background: var(--bright);
}

.dropdown {
  position: absolute;
  right: 0;
  background-color: var(--bright);
  border: 1px solid var(--purpletronic-15);
  border-radius: 16px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  padding: 10px;
  width: max-content;
  z-index: 999;
}

.dropdown ul {
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 5px;
  cursor: pointer;
  font-weight: 700;
  color: var(--purpletronic);
}

.dropdown li:hover {
  background: var(--purpletronic-15);
  border-radius: 8px;
}
