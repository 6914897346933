@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --purpletronic: #8080f8;
  --purpletronic-5: #8080f80d;
  --purpletronic-10: #8080f81a;
  --purpletronic-15: #8080f826;
  --purpletronic-20: #8080f833;
  --purpletronic-30: #8080f84d;
  --purpletronic-40: #8080f866;
  --purpletronic-50: #8080f880;
  --seadapted: #34e9aa;
  --seadapted-5: #34e9aa0d;
  --seadapted-10: #34e9aa1a;
  --seadapted-15: #34e9aa26;
  --seadapted-20: #34e9aa33;
  --seadapted-30: #34e9aa4d;
  --seadapted-40: #34e9aa66;
  --seadapted-50: #34e9aa80;
  --sandground: #fa9f89;
  --sandground-5: #fa9f890d;
  --sandground-10: #fa9f891a;
  --sandground-15: #fa9f8926;
  --sandground-20: #fa9f8933;
  --sandground-30: #fa9f894d;
  --sandground-40: #fa9f8966;
  --sandground-50: #fa9f8980;
  --deepblue: linear-gradient(
    180deg,
    var(--purpletronic) 0%,
    var(--seadapted) 100%
  );
  --outspace: linear-gradient(
    180deg,
    var(--purpletronic) 0%,
    var(--sandground) 100%
  );
  --shade: #141415;
  --bright: #ffffff;
  --bright-50: #ffffff80;
  --grey: #383838;
  --dark-grey: #3f3e3e;
  --dark-grey-20: #a59f9f33;
  --dark-grey-30: #3f3e3e4d;
  --dark-grey-40: #3f3e3e66;
  --dadada: #dadada;

  --dark-color: #2c2738;
  --bright-color: #ebf4f8;
  --success-color: #14a38b;
  --warning-color: #f2ac57;
  --accent-color: #0880ae;
  --error-color: #ff7171;
  --error-color-50: #ff717180;
  --secondary-error-color: #ffdad1;
  --disabled-color: #dbe2ea;
  --disabled-color-24: #2c27383d;
  --text-color-item: #756f86;
  --text-color-menu-item: #979797;

  /* common typography*/
  --font-size-10: 0.625rem;
  --font-size-11: 0.6875rem;
  --font-size-12: 0.75rem; /* 12px */
  --font-size-13: 0.8125rem;
  --font-size-14: 0.875rem;
  --font-size-16: 1rem;
  --font-size-17: 1.0625rem;
  --font-size-18: 1.125rem;
  --font-size-19: 1.1875rem;
  --font-size-20: 1.25rem;
  --font-size-21: 1.3125rem;
  --font-size-22: 1.375rem;
  --font-size-24: 1.5rem;
  --font-size-25: 1.5625rem;
  --font-size-28: 1.75rem;
  --font-size-30: 1.875rem;
  --font-size-32: 2rem;
  --font-size-34: 2.125rem;
  --font-size-35: 2.1875rem;
  --font-size-44: 2.75rem;
  --font-size-45: 2.8125rem;
  --font-size-50: 3.125rem;

  /* common line-heights */
  --line-heights-12: 0.75rem;
  --line-heights-14: 0.875rem;
  --line-heights-16: 1rem;
  --line-heights-18: 1.125rem;
  --line-heights-19: 1.1875rem;
  --line-heights-20: 1.25rem;
  --line-heights-22: 1.375rem;
  --line-heights-24: 1.5rem;
  --line-heights-25: 1.5625rem;
  --line-heights-26: 1.625rem;
  --line-heights-28: 1.75rem;
  --line-heights-30: 1.875rem;
  --line-heights-32: 2rem;
  --line-heights-35: 2.1875rem;
  --line-heights-36: 2.25rem;
  --line-heights-40: 2.5rem;
  --line-heights-44: 2.75rem;
  --line-heights-48: 3rem;
  --line-heights-60: 3.75rem;
  --line-heights-72: 4.5rem;
  --line-heights-88: 5.5rem;

  /* common letter-spacing */
  --letter-spacing-0: -0.008rem;
  --letter-spacing-1: 0.013rem;
  --letter-spacing-2: 0.019rem;
  --letter-spacing-3: 0.031rem;
  --letter-spacing-4: 0.038rem;
  --letter-spacing-5: 0.05rem; /* 0,8px */
  --letter-spacing-6: 0.056rem;
  --letter-spacing-7: 0.063rem;

  /* mozilla */
  scrollbar-color: rgba(150, 150, 150, 0.5) transparent !important;
  scrollbar-width: thin !important;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
}
a {
  text-decoration: none;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.5);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(150, 150, 150, 0.8);
}
