.cardContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.card {
  display: grid;
  grid-template-rows: repeat(auto-fit, minmax(100px, auto));
  gap: 5px;
}
.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.fullNameInput,
.dniInput {
  width: max(450px, 40%);
}

.iconWrapper {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purpletronic);
  border-radius: 50%;
  cursor: pointer;
}
.iconEraser {
  width: 15px;
  height: 15px;
}
.tooltipContainer {
  position: relative;
  display: inline-block;
}

.tooltipText {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.193);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 3px 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0;
  transition: visibility 0s, opacity 0.2s linear;
}

.tooltipContainer:hover .tooltipText {
  visibility: visible;
  opacity: 1;
}
