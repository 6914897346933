.section {
  width: max(100%, 420px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.card {
  width: min(90%, 450px);
  min-height: 280px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 26px;
  margin-top: 40px;
  padding: 10px;
}
.cardContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 15px;
}
.datePicker {
  width: 100%;
}

.personalInfoContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.actions {
  display: inline-flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}
