.blackboard {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}
.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.step1 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  gap: 5%;
}

.svg {
  width: 40%;
  height: 30%;
}

.video {
  min-width: inherit;
  width: inherit;
  height: 100%;
  border-radius: inherit;
  cursor: pointer;
}
