.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.8rem;
  width: 100%;
  height: 100vh;
  min-width: 500px;
  min-height: 100vh;
  background: var(--purpletronic);
  mix-blend-mode: normal;
  position: relative;
  overflow-y: auto;
}

.classfyLogo {
  left: 0;
  top: 0;
  padding-left: 4.5em;
  padding-top: 2.5em;
  position: absolute;
  width: 216px;
  height: 130px;
  z-index: 1;
}

.images {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 470px;
  height: 250px;
}
.images .mainSvg {
  position: absolute;
  margin-right: 20px;
}
.images .backgroundSvg {
  position: absolute;
  margin-top: 13px;
  right: 6px;
}

.innerContainer {
  display: flex;
  position: relative;
  flex-direction: column;
}

.email,
.password {
  width: 393.33px;
  height: 53.79px;
  background: var(--bright);
  border-radius: 34px;
  border: 1px solid var(--purpletronic-50);
  box-sizing: border-box;
  padding: 12px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  color: var(--shade);
}

.email:focus-visible,
.password:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic);
}
.email::placeholder,
.password::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.emailError,
.passwordError {
  border: 1px solid var(--sandground);
}
.emailError::placeholder,
.passwordError::placeholder {
  color: var(--sandground);
  opacity: 0.5;
  font-weight: 700;
}

.passwordInputContainer {
  position: relative;
  margin-top: 25px;
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.links {
  display: flex;
  justify-content: space-around;
  margin-top: 8px;
}

.links span {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  text-decoration-line: underline;
  color: var(--seadapted);
}

.actions {
  width: 270px;
  display: inline-flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}
.actions button {
  border-radius: 42px !important;
}

.actions .errorMessage {
  width: fit-content;
  height: 56px;
  background: var(--secondary-error-color);
  border-radius: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.actions .errorMessage .alertSvg {
  width: 43px;
  height: 43px;
  margin: 0 20px;
}
.actions .errorMessage > p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-14);
  line-height: var(--line-heights-19);
  color: var(--sandground);
  margin-right: 20px;
}

.register {
  margin: 18px 0;
}
.register > span {
  font-style: normal;
  font-weight: 500;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  color: white;
}

.register a span {
  font-weight: 700;
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-12);
  line-height: var(--line-heights-16);
  text-decoration-line: underline;
  color: var(--seadapted);
}

@media (width < 500px) {
  .main {
    min-width: 250px;
    padding: 0 1rem 1.5rem 1rem;
    gap: 2rem;
  }
  .classfyLogo {
    padding: 1rem;
    position: relative;
    display: flex;
    height: 70px;
  }
  .images {
    width: 100%;
    height: 150px;
  }
  .innerContainer {
    width: 100%;
  }
  .email,
  .password {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
  .links {
    display: grid;
  }
  .actions {
    flex-direction: column;
    width: auto;
  }
}
