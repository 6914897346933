.chatContainer {
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 450px;
  border: 1px solid var(--dark-grey-20);
  border-radius: 5px;
  overflow: hidden;
}
.chatWindow {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.messageContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  max-width: 80%;
  user-select: text;
}
.sent {
  align-self: flex-end;
  border-radius: 5px;
  padding: 8px;
}
.received {
  align-self: flex-start;
  border-radius: 5px;
  padding: 8px;
}
.messageText {
  padding: 8px;
  border-radius: 5px;
  max-width: 100%;
  word-wrap: break-word;
  background-color: var(--seadapted-50);
}
.messageTextReceived {
  padding: 8px;
  border-radius: 5px;
  max-width: 100%;
  word-wrap: break-word;
  background-color: var(--purpletronic-30);
}
.toolbar {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 2px;
}
.timestamp {
  font-size: var(--font-size-10);
  color: var(--dark-grey-40);
}
.copyIcon {
  cursor: pointer;
  width: fit-content;
  height: var(--font-size-10);
}
.textAreaContainer {
  border-top: 1px solid var(--dark-grey-20);
  display: flex;
  align-items: center;
  padding: 10px;
}
.sendButton {
  margin: 1px 0 0 7px;
  outline: none;
}
