.alertContainer {
  width: 100%;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  position: relative;
}

.primary {
  background-color: var(--purpletronic-10);
  border-color: var(--purpletronic);
}

.secondary {
  background-color: var(--seadapted-10);
  border-color: var(--seadapted);
}

.tertiary {
  background-color: var(--sandground-10);
  border-color: var(--sandground);
}

.alertContent {
  text-align: center;
}

.alertTitle {
  margin-bottom: 0.5rem;
}

.primary .alertTitle {
  color: var(--purpletronic);
}

.secondary .alertTitle {
  color: var(--seadapted);
}

.tertiary .alertTitle {
  color: var(--sandground);
}

.alertMessage {
  font-size: var(--font-size-14);
  color: var(--shade);
  line-height: var(--line-heights-20);
}

.closeIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  border-radius: 16px;
  background: var(--dark-grey-40);
}
