.section {
  height: 100vh;
  padding: 20px;
}
.containerLogo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
}
.classfyLogo {
  padding: 0 30px 0 50px;
  width: 200px;
}

.title {
  font-size: clamp(var(--font-size-28), 6vw, var(--font-size-32));
  padding: 0 30px 0 50px;
  margin: 30px 0 80px 0;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 430px;
  height: 400px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 30px;
  padding: 20px;
  margin: 0 auto;
  overflow: hidden;
}

.imgContainer {
  width: 100%;
  height: 80%;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.defaultImage {
  width: 80%;
  height: 80%;
}
.cardActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px;
  margin-top: auto;
}

@media (max-width: 667px) {
  .containerLogo {
    margin: 0;
    justify-content: center;
    text-align: center;
  }
  .classfyLogo {
    width: 55%;
    margin: 30px auto 10px;
  }
  .title {
    text-align: center;
    margin: 20px 0 45px 0;
  }
  .card {
    max-width: 100%;
    width: auto;
    max-height: 350px;
  }

  .imgContainer {
    max-height: 200px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .defaultImage {
    width: 60%;
    height: auto;
  }

  .cardActions {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .cardActions button {
    margin-top: 10px;
  }

  .cardActions button span {
    font-size: var(--font-size-12);
    line-height: var(--line-heights-14);
  }
}
