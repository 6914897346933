.container {
  gap: 60px;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  user-select: none;
}

.disabled {
  opacity: 0.75;
  cursor: not-allowed;
}

/* actions */

.actionsContainer {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 15px;
}
.actions {
  display: flex;
  align-items: center;
}

.chevron {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.chevronDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 1px;
  color: var(--purpletronic);
  padding: 0 5px;
  min-width: 170px;
  text-transform: capitalize;
}

.downloadICSContainer {
  margin-left: auto;
}

.downloadICSTooltip {
  text-wrap: balance;
  width: 220px;
  text-align: center;
}
.downloadICSButton {
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--purpletronic);
}

.alert {
  font-style: normal;
  font-weight: 700;
  font-size: var(--font-size-14);
  line-height: var(--line-heights-35);
  color: var(--dark-grey-40);
  margin-left: auto;
}

.qr {
  background: var(--bright);
  padding: 1.75rem 2rem 1rem;
  border-radius: 16px;
  border: 1px solid var(--purpletronic-20);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.calendarWrapper {
  border-radius: 19px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  padding: 16px;
}

.calendar {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  width: 100%;
}

.calendarHeader {
  color: var(--seadapted);
  font-weight: 700;
  text-align: start;
  vertical-align: top;
  border: 1px dashed var(--purpletronic-15);
  border-top: 0;
  padding: 0 0 4px 4px;
}

.calendarWeek {
  height: 125px;
}

.dateIdentifier {
  color: var(--disabled-color-24);
  font-weight: 400;
  padding: 0 10px;
  width: fit-content;
  margin: 3px 0 5px auto;
}
.dateIdentifierActive {
  color: var(--purpletronic);
}
.dateIdentifierToday {
  color: var(--bright);
  background-color: var(--seadapted);
  border-radius: 50px;
  font-weight: 600;
}
.calendarDay {
  border: 1px dashed var(--purpletronic-15);
  vertical-align: top;
  padding: 0 2px;
  opacity: 0.5;
}
.calendarDayToday {
  background: var(--purpletronic-10);
}

.calendarCurrenteMonth {
  opacity: 1;
}

.attendanceWrapper {
  padding: 2px 0;
  margin-bottom: 3px;
  margin-inline: auto;
  width: 96%;
  border: 1px solid var(--purpletronic-15);
  background: var(--purpletronic-15);
  border-radius: 8px;
}
.ratingContainer {
  text-align: center;
}

.attendanceInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  font-size: var(--font-size-11);
  font-weight: 700;
  color: var(--purpletronic);
  padding: 2px;
  white-space: nowrap;
}
.attendanceCircle {
  border: 1px solid var(--bright);
  border-radius: 50%;
  display: block;
  height: 10px;
  margin: 4px 0 0 0;
  width: 10px;
}
.attendanceCircle.--POR_DEFINIR {
  background: yellow;
  border: 1px solid var(--dark-grey-30);
}
.attendanceCircle.--VIENE {
  background: var(--seadapted);
}
.attendanceCircle.--FALTA {
  background-color: var(--error-color);
}
.attendanceCircle.--FALTA_JUSTIFICADA {
  background-color: var(--sandground-50);
}

.calendarLink {
  font-weight: lighter;
  text-decoration-line: underline;
  cursor: pointer;
}

.calendarModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 25px 25px 10px;
}

.downloadICSLink {
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--seadapted);
}
