.section {
  height: calc(100vh - 105px) !important;
  width: 100%;
  display: flex;
}

.leftContent {
  width: 100%;
  min-width: 600px;
  padding: 0 1rem 0 0;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
}

.barbeibotLogo {
  width: 150px;
}

.dotsSpinner {
  font-size: var(--font-size-21);
  color: var(--seadapted);
  font-weight: bolder;
}
.limit {
  display: flex;
  color: var(--text-color-menu-item);
  gap: 0.75rem;
}
.limit span {
  font-weight: bolder;
  color: var(--purpletronic);
}

.selectedDate {
  color: var(--purpletronic);
}

.alert {
  margin: 1rem 2rem 2rem;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.questionArea {
  display: grid;
  gap: 1rem;
  grid-template-columns: 250px minmax(250px, 1fr);
  margin: 1rem 2rem 2rem;
  align-items: center;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

.emptyImg {
  width: 100%;
  height: 100%;
  border: 2px dashed var(--purpletronic-40);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  display: flex;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  position: relative;
  margin: 0 auto;
}
.img {
  align-self: center;
  justify-self: center;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  object-fit: contain;
  border: 1px solid var(--purpletronic-40);
  border-radius: 8px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: zoom-in;
  transition: transform 0.3s ease;
}
.rotateIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  min-width: 3rem;
  bottom: -2.5rem;
}
.rotateLeftIcon,
.rotateRightIcon {
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.rotateRightIcon {
  transform: scaleX(-1);
}

.crossIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--dark-grey);
  border-radius: 50%;
}

.interaction {
  display: flex;
  height: inherit;
}
.textArea {
  width: 100%;
  height: 100%;
}
.sendButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 2rem;
}
.sendButtonText {
  display: flex;
  gap: 0.5rem;
}
.sendIcon {
  width: 15px;
  cursor: pointer;
}
.spinner {
  font-weight: 700;
  font-size: var(--font-size-50);
  color: var(--seadapted);
}
.markdown {
  position: relative;
  padding: 2rem 4rem;
  user-select: text;
}
