.section {
  height: 100%;
  width: 100%;
}
.title {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
.backIcon {
  width: 21px;
  height: 22px;
  cursor: pointer;
  margin: 0 20px 0 0;
}

.card {
  width: fit-content;
  min-width: 450px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 20px auto 0 auto;
  padding: 20px;
}
.proposalName {
  font-weight: 600;
}
.subjectsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.subject {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 40px;
}
.subscriptionDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.divider {
  margin: 10px 0;
  border-bottom: 1.5px solid var(--purpletronic);
}
.totalContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
}

.acceptButton {
  align-self: flex-end;
  margin-top: 25px;
}
.confirmContainer {
  width: 450px;
  height: 100px;
  padding: 15px 0;
}

.conditionsContainer {
  margin: 20px 0;
  padding: 10px;
}
.conditionText {
  margin: 2px 0;
  font-size: var(--font-size-12);
}
.dateText {
  margin: 6px 9px;
  font-size: var(--font-size-14);
}
