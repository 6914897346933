.a {
  font-style: normal;
  font-weight: 700;
  text-decoration-line: underline;
  cursor: pointer;
}

.primary {
  color: var(--purpletronic);
}

.secondary {
  color: var(--seadapted);
}

.tertiary {
  color: var(--sandground);
}
