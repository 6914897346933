.Pizarra {
  /* margin-top: 30px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Pizarra[data-status='inactive'] {
  display: none;
}

.PizarraCanvas {
  --canvas-width: calc(100vw - 35vw);
}

.PizarraCanvas {
  margin: 5px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  width: var(--canvas-width);
  height: calc(var(--canvas-width) / 2.7);
  box-sizing: border-box;
  cursor: crosshair;
}
