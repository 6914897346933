.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.teacherHeader {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 40px;
}
.teacherSubheader {
  margin-top: 7px;
  color: var(--grey);
}
.scheduleClass {
  display: flex;
  align-items: center;
  gap: 20px;
}

.spinner {
  font-weight: 700;
  font-size: var(--font-size-50);
  color: var(--seadapted);
}

.carousel {
  width: 100%;
  cursor: grab;
}
.slide {
  padding: 1rem;
}

.verticalTeacherContainer {
  min-width: 260px;
  width: 260px;
  min-height: 400px;
  height: 400px;
  background: var(--purpletronic-50);
  padding: 35px 7px;
}
.verticalTeacherInnerContainer {
  gap: 1.25rem;
}
.verticalTeacherImg {
  min-width: 90px;
  min-height: 90px;
  max-width: 90px;
  max-height: 90px;
  width: 90px;
  height: 90px;
  border: 3px solid var(--purpletronic);
}
.verticalTeacherName {
  font-weight: 600;
  font-size: var(--font-size-13);
  line-height: var(--font-size-18);
  letter-spacing: var(--letter-spacing-4);
  color: var(--purpletronic);
}
.verticalTeacherVector {
  height: 18px;
  width: 50px;
}
.verticalTeacherSubjects {
  width: 90%;
  height: 50px;
}

@keyframes highlightAnimation {
  0% {
    -webkit-transform: scaleX(1.05);
    -moz-transform: scaleX(1.05);
    -ms-transform: scaleX(1.05);
    transform: scaleX(1.05);
    border-color: var(--seadapted);
  }
  50% {
    -webkit-transform: scaleX(1);
    -moz-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
  100% {
    -webkit-transform: scaleX(1.05);
    -moz-transform: scaleX(1.05);
    -ms-transform: scaleX(1.05);
    transform: scaleX(1.05);
    border-color: var(--seadapted);
  }
}

.bookingButton {
  -webkit-animation: highlightAnimation 1.5s infinite;
  -moz-animation: highlightAnimation 1.5s infinite;
  -ms-animation: highlightAnimation 1.5s infinite;
  animation: highlightAnimation 1.5s infinite;
  overflow: hidden;
}
