.card {
  min-width: 360px;
  width: 360px;
  min-height: 300px;
  height: 300px;
  background: var(--purpletronic);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
}
.a {
  width: 100%;
  height: 100%;
}
.category {
  position: absolute;
  left: 1rem;
  top: 1rem;
  padding: 0.25rem 1rem;
  background: var(--bright);
  border-radius: 2rem;
  font-weight: 500;
  font-size: var(--font-size-10);
  letter-spacing: var(--letter-spacing-4);
}

.title {
  position: absolute;
  width: 90%;
  bottom: 2.5rem;
  padding-inline: 1.5rem;
  font-weight: 700;
  font-size: var(--font-size-14);
  line-height: var(--font-size-28);
  letter-spacing: var(--letter-spacing-4);
  text-align: left;
  color: var(--bright);
  z-index: 1;
}
