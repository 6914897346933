.tabs[data-tab-number='4'] {
  --tab-number: 4;
}

.tabs[data-tab-number='5'] {
  --tab-number: 5;
}
.tabs {
  width: 100%;
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(var(--tab-number), 1fr);
  place-items: center;
  margin: 0 auto;
  text-align: center;
}
.disabled {
  cursor: not-allowed !important;
  z-index: 100;
}
.itemContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}
.itemContainer[data-status='false']:hover {
  opacity: 1;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: var(--purpletronic);
  border-radius: 50px;
  margin-bottom: 8px;
  position: relative;
}
.unfocused {
  opacity: 0.3;
}
.icon {
  width: 18px;
  height: 18px;
}
.label {
  color: var(--purpletronic);
}

.matchingTab {
  background: var(--seadapted);
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  right: -2px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(144, 144, 174, 0.8),
    0 0 20px rgba(144, 144, 174, 0.5);
}
.matchingTab[data-status='false'] {
  background: var(--error-color);
}
