.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 25px;
  padding: 20px;
  min-width: 350px;
}

.cardInputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0;
}
.cardInput {
  padding: 10px;
  border: 2px solid var(--purpletronic-30);
  border-radius: 34px;
}
.cardholderInput {
  padding: 8px;
  font-size: var(----font-size-16);
  border: 2px solid var(--purpletronic-30);
  color: #32325d;
  border-radius: 34px;
  outline: none;
}
.cardholderInput::placeholder {
  color: var(--purpletronic-40);
}
.formActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
