.section {
  height: 100%;
  width: 100%;
}
.container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 30px 0;
}
