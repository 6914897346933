.modal {
  padding: 25px 25px 0;
  width: 65vw;
  max-width: 65vw;
}
.selectedDate {
  color: var(--text-color-menu-item);
}

.photoArea {
  display: flex;
  gap: 1rem;
  margin: 1rem 2rem 2rem;
  align-items: center;
  height: 250px;
  min-height: 250px;
  max-height: 250px;
}

.emptyImg {
  width: 100%;
  height: 100%;
  border: 2px dashed var(--purpletronic-40);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgContainer {
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  position: relative;
  margin: 0 auto;
}
.img {
  align-self: center;
  justify-self: center;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  object-fit: contain;
  border: 1px solid var(--purpletronic-40);
  border-radius: 8px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: zoom-in;
}

.paragraph {
  color: var(--purpletronic);
}
.answer {
  position: relative;
  padding: 0 2rem;
  user-select: text;
}
.markdown {
  position: relative;
  padding: 0 2rem 0;
  user-select: text;
}
