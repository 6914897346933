.container {
  width: 100%;
  height: var(--blackboardCalculatedHeight);
}

.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.bot {
  width: inherit;
  height: inherit;
  border-radius: 16px;
}

.stepToolbar {
  padding: 1.25rem;
}

.stepContent {
  height: calc(100% - 6rem);
  border-radius: 16px;
  overflow-y: auto;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  border-radius: inherit;
}
.svg {
  width: 20%;
  height: 30%;
}
.svgWithContent {
  max-width: 100px;
}

.questionArea {
  display: grid;
  gap: 1rem;
  grid-template-columns: 250px 1fr;
  margin: 1rem 4rem 2rem;
  align-items: center;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}

.emptyImg {
  width: 100%;
  height: 100%;
  border: 2px dashed var(--purpletronic-40);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.imgContainer {
  display: flex;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  position: relative;
  margin: 0 auto;
}
.img {
  align-self: center;
  justify-self: center;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  max-height: inherit;
  object-fit: contain;
  border: 1px solid var(--purpletronic-40);
  border-radius: 8px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  cursor: zoom-in;
  transition: transform 0.3s ease;
}
.rotateIconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
  min-width: 3rem;
  bottom: -2.5rem;
}
.rotateLeftIcon,
.rotateRightIcon {
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.rotateRightIcon {
  transform: scaleX(-1);
}
.crossIcon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--dark-grey);
  border-radius: 50%;
}

.interaction {
  display: flex;
  height: inherit;
}
.textArea {
  width: 100%;
  height: 100%;
}
.sendButton {
  display: flex;
  justify-content: flex-end;
  margin-right: 4rem;
}
.sendButtonText {
  display: flex;
  gap: 0.5rem;
}
.sendIcon {
  width: 15px;
  cursor: pointer;
}
.spinner {
  font-weight: 700;
  font-size: var(--font-size-50);
  color: var(--seadapted);
}
.markdown {
  position: relative;
  padding: 2rem 4rem;
  user-select: text;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools .right {
  display: flex;
  gap: 10px;
}
.tools .right .innerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43px;
  height: 43px;
  background: var(--seadapted);
  box-shadow: 10px 20px 35px 8px rgba(128, 128, 248, 0.05);
  border-radius: 21.5px;
  cursor: pointer;
}
.tools .right .innerRight .uploadButton {
  width: 16px;
  height: 18px;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}

.step1 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 15px;
}
.step1Empty {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  text-align: center;
  padding: 15px;
}

.imageSync {
  width: inherit;
  height: inherit;
  overflow: auto;
}

.imageSync .day:not(:last-of-type)::after {
  height: 1px;
  display: block;
  background: var(--purpletronic);
  margin: 10px auto;
  content: ' ';
}

.imageSync .title {
  text-transform: uppercase;
  margin-top: 0;
  padding-left: 20px;
  font-weight: 600;
}
.imageSync .content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}

.imageSync .image {
  cursor: pointer;
  margin: 10px;
  transition: all 0.2s;
  position: relative;
  max-width: 65vw;
}
.imageSync .image:hover {
  transform: scale(1.05);
}

.imageSync .selected {
  transform: scale(1.1);
  box-shadow: 0px 0px 3px 3px var(--purpletronic);
}

.imageSyncActions {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 30px;
}
