.input {
  min-width: 100px;
  width: 100%;
  height: 45px;
  background: var(--bright);
  border-radius: 34px;
  border: 1px solid var(--purpletronic-40);
  box-sizing: border-box;
  padding: 5px 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.input:focus-visible {
  outline: 0;
  border: 1px solid var(--purpletronic);
}
.input::placeholder {
  color: var(--purpletronic);
  opacity: 0.5;
}

.disabled {
  border: 1px solid var(--purpletronic-15);
  color: var(--purpletronic-30);
  cursor: not-allowed;
}

.disabled::placeholder {
  color: var(--purpletronic-30);
  opacity: 0.5;
}

.error {
  border: 1px solid var(--sandground);
}

.passwordInputContainer {
  position: relative;
  min-width: 100px;
  width: 100%;
  margin: 5px;
}

.eyeIcon {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
