.blackboard {
  --cuadernillo-canvas-height: 79vh;
  width: calc(var(--cuadernillo-canvas-height) / 0.9);
  height: var(--cuadernillo-canvas-height);
  margin-inline: auto;
}
.innerContainer {
  width: inherit;
  height: inherit;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 16px;
  margin-bottom: 6px;
}

.step1,
.step2 {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  gap: 5%;
  padding: 45px;
}
.svg {
  width: 40%;
  height: 30%;
}

.step1 button {
  padding: 0 15px;
}

.canvas {
  width: inherit;
  height: 100%;
  border-radius: inherit;
  cursor: crosshair;
}
.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tools .right {
  display: flex;
  gap: 10px;
}
.tools .right .innerRight {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 5px;
  height: 43px;
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.2);
  border-radius: 21.5px;
}
.tools .right .innerRight .icons {
  width: 22px;
  height: 18px;
  margin: 0 8px;
  cursor: pointer;
}
.disabled {
  cursor: not-allowed !important;
}

.zoomImageAdjusted {
  width: calc(100vw - 120px);
  height: auto;
  cursor: zoom-out;
  padding: 8px;
}

.canvasAdjusted {
  width: calc(100vw - 120px);
  height: var(--maxModalContentHeight);
  cursor: zoom-in;
}
