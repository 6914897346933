.container {
  min-width: 360px;
  width: 360px;
  min-height: 300px;
  height: 300px;
  background: var(--purpletronic);
  box-shadow: 10px 10px 35px 8px rgba(144, 144, 174, 0.3);
  border-radius: 17px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 15px 35px;
  position: relative;
  border-image: linear-gradient(var(--purpletronic-15), var(--purpletronic-30))
    fill 1;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: auto;
  vertical-align: bottom;
}

.title {
  font-weight: 700;
  font-size: var(--font-size-28);
  line-height: var(--font-size-44);
  letter-spacing: var(--letter-spacing-4);
  text-align: center;
  color: white;
  z-index: 1;
}
